import React from 'react';
import { Box, Divider, Link, Stack, SvgIcon, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { RemoveItem } from '@aph/components/checkout/cart/remove-item';
import { QuantitySelector } from '@aph/components/common/quantity-selector/quantity-selector.component';
import InfoIcon from '@aph/icons/info_i.svg';
import { formatItemName } from '@aph/utilities/format-item-name/format-item-name';
import { ArticleImage } from '~/articles/components/article-image/article-image.component';
import type { Promotion } from '~/services/generated/PresentationClient';
import { CostType } from '~/services/generated/PresentationClient';
import type { CostDetail, ILineItemImageReference } from '~/services/generated/PurchaseClient';
import theme from '~/styles/theme';
import { PriceFormatter } from '../price/price-formatter.component';
import { PromotionLabel } from '../promotion-label/promotion-label';

export const MiniCartLineItemComponent: React.FC<{
  title: string;
  imageUrl?: string;
  image?: ILineItemImageReference;
  articleUrl?: string;
  quantity: number;
  promotions?: Promotion[];
  maxQuantity?: number;
  priceDetails: CostDetail[] | undefined;
  rxItem: boolean;
  setQuantity: (quantity: number) => void;
  updateQuantity: (oldQuantity: number, newQuantity: number) => Promise<void>;
  onRemoveLineItem: () => void;
}> = ({
  title,
  imageUrl,
  image,
  articleUrl,
  quantity,
  setQuantity,
  maxQuantity,
  updateQuantity,
  priceDetails,
  promotions,
  onRemoveLineItem,
  rxItem,
}) => {
  const maxPurchaseReasonText = rxItem ? 'MAXPURCHASE.TEXT.RX' : 'MAXPURCHASE.TEXT';
  const maxPurchaseReasonQuantity = rxItem ? 'TOOLTIP.RX_QUANTITY_LIMIT' : 'MINICART.MAX_QUANTITY';

  const originalPrice = priceDetails?.find((i) => i.type === CostType.OriginalPrice)?.value || 0;
  const reducedPrice = rxItem
    ? priceDetails?.find((i) => i.type === CostType.SubsidizedPrice)?.value || 0
    : priceDetails?.find((i) => i.type === CostType.DiscountPrice)?.value || 0;

  const cartItemImageURL = image?.url || imageUrl;

  return (
    <Box>
      <Stack p="16px">
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" columnGap={2}>
          <Box minWidth="72px">
            <ArticleImage
              height={72}
              width={72}
              preset="medium"
              objectFit="contain"
              layout="fixed"
              src={{ url: cartItemImageURL, publicId: image?.cloudinaryPublicId }}
              shouldUseRxFallback={rxItem}
              alt={`Image of ${title}`} // TDOD ADD ALT-TEXT FROM BE
            />
          </Box>
          <Stack flexGrow="2">
            <Box mb={2}>
              <Stack direction="row" justifyContent="space-between" alignItems="baseline" gap={1}>
                <Link
                  href={articleUrl}
                  variant="normalBold"
                  underline="none"
                  component={articleUrl ? 'a' : 'p'}
                  color={theme.palette['color/text/default']}
                  py={1}
                >
                  {formatItemName(title)}
                </Link>
                <Box mr={-1}>
                  <RemoveItem removeItem={onRemoveLineItem} />
                </Box>
              </Stack>
              {promotions &&
                promotions.map((p: Promotion) => (
                  <PromotionLabel
                    promotionText={p.promotionDisplayName}
                    promotionTarget={p.promotionTarget}
                    key={p.promotionDisplayName}
                  />
                ))}
            </Box>
            <Stack gap={1}>
              {maxQuantity && (
                <Stack direction="row" gap={1} alignItems="center">
                  <SvgIcon color="primary" fontSize="inherit">
                    <InfoIcon />
                  </SvgIcon>
                  <Typography variant="xs" color={theme.palette['color/text/subtle']}>
                    <FormattedMessage id={maxPurchaseReasonText} values={{ maxQuantity }} />
                  </Typography>
                </Stack>
              )}
              <Stack direction="row" justifyContent="space-between">
                <Box minWidth={122}>
                  <QuantitySelector
                    data-testid="ADD_ARTICLE_TO_CART.BUYABLE_BUTTON.QUANTITY_SELECTOR"
                    quantity={quantity}
                    maxQuantity={maxQuantity || undefined}
                    maxQuantityReason={
                      maxQuantity ? (
                        <FormattedMessage id={maxPurchaseReasonQuantity} values={{ maxQuantity }} />
                      ) : undefined
                    }
                    minQuantity={0}
                    setQuantity={setQuantity}
                    updateFunctionFromParent={updateQuantity}
                  />
                </Box>
                <Stack direction="column" alignItems="flex-end" justifyContent="space-evenly">
                  {originalPrice !== reducedPrice ? (
                    <>
                      <PriceFormatter
                        price={reducedPrice}
                        size="medium"
                        color={rxItem ? 'color/text/default' : 'color/text/notice'}
                      />
                      <PriceFormatter
                        price={originalPrice}
                        strikeThrough
                        size="xsmall"
                        color="color/text/subtle"
                      />
                    </>
                  ) : (
                    <PriceFormatter price={originalPrice} size="medium" />
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Divider />
    </Box>
  );
};
