import { type PropsWithChildren } from 'react';
import Stack from '@mui/material/Stack';
import type { ICarrierFields } from '../types';
import { ContentfulImage } from './next-contentful-image/next-contentful-image';

export const Carrier = ({ logo, children }: Pick<ICarrierFields, 'logo'> & PropsWithChildren) => (
  <>
    <Stack alignItems="flex-start" marginBottom={2} marginTop={3}>
      <ContentfulImage
        alt={logo.fields.altText ?? ''}
        height={logo.fields.image?.fields.file.details.image?.height}
        width={logo.fields.image?.fields.file.details.image?.width}
        src={logo.fields.image?.fields.file.url ?? ''}
      />
    </Stack>
    {children}
  </>
);
