import React from 'react';
import { Skeleton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import type { IArticle, Price } from '~/articles/generated/ArticlesClient';
import { PromotionTarget } from '~/articles/generated/ArticlesClient';
import type { TextSize } from '~/styles/text-size';
import { PriceFormatter } from '../new-price/price-formatter/price-formatter.component';

export const PricesGroup: React.FC<{
  price?: Price;
  size?: TextSize;
  spread?: boolean;
  showOnlyOnePrice?: boolean;
  loading?: boolean;
}> = ({ price, size, spread, showOnlyOnePrice, loading }) => {
  const { salesInformation, webPrice } = price || {};
  const theme = useTheme();

  if (loading) {
    return (
      <Skeleton height={41}>
        <PriceFormatter
          price={100}
          size={size}
          label={<FormattedMessage id="ARTICLE.CAMPAIGN" />}
        />
      </Skeleton>
    );
  }

  if (!webPrice) return null;

  if (salesInformation?.promotionTarget && salesInformation.salesPrice) {
    // show discount price and web price
    const { promotionTarget, salesPrice } = salesInformation;
    const isClubPrice = promotionTarget === PromotionTarget.ClubMember;
    return (
      <Stack
        justifyContent={spread ? 'space-between' : 'flex-start'}
        width={spread ? '100%' : undefined}
        direction="row"
        gap={spread ? 1 : 3}
        alignItems="flex-end"
        data-testid="root"
      >
        <PriceFormatter
          price={salesPrice}
          size={size}
          color={
            isClubPrice ? theme.palette['color/text/brand'] : theme.palette['color/text/notice']
          }
          label={<FormattedMessage id={isClubPrice ? 'ARTICLE.CLUB' : 'ARTICLE.CAMPAIGN'} />}
        />
        {!showOnlyOnePrice && (
          <PriceFormatter
            price={webPrice}
            size={size}
            strikeThrough
            color={theme.palette['color/text/subtle']}
            label={<FormattedMessage id="ARTICLE.WEB" />}
          />
        )}
      </Stack>
    );
  }

  return (
    <Stack
      justifyContent={spread ? 'space-between' : 'flex-start'}
      width={spread ? '100%' : undefined}
      direction="row"
      gap={spread ? 1 : 3}
      data-testid="root"
    >
      <PriceFormatter
        price={webPrice}
        size={size}
        color={theme.palette['color/text/brand']}
        label={<FormattedMessage id="ARTICLE.WEB" />}
      />
    </Stack>
  );
};

export const PricesGroupContainer = ({
  product,
  loading,
}: {
  product: IArticle;
  loading?: boolean;
}) => {
  const theme = useTheme();

  if (loading) {
    return (
      <Stack direction="row" gap={1} height={50}>
        <Skeleton>
          <Typography variant="h1">priser</Typography>
        </Skeleton>
        <Skeleton>
          <Typography variant="h1">priser</Typography>
        </Skeleton>
      </Stack>
    );
  }

  if (!product.price) {
    return null;
  }

  const { price, isWebSaleOnly } = product;
  const { retailPrice, webPrice = 0 } = price;
  const showRetailPriceComponent = retailPrice && retailPrice > webPrice && !isWebSaleOnly;

  return (
    <Stack gap={1}>
      <PricesGroup price={price} />
      <Stack>
        {price.comparisonPrice?.pricePerUnit && (
          <PriceFormatter
            price={price.comparisonPrice.pricePerUnit}
            inline
            label={<FormattedMessage id="ARTICLE.COMPARISON" />}
            color={theme.palette['color/text/subtle']}
            unit={
              price.comparisonPrice.unit ? (
                <FormattedMessage id={`ARTICLE.COMPARISON.UNIT.${price.comparisonPrice.unit}`} />
              ) : undefined
            }
          />
        )}
        {showRetailPriceComponent && (
          <PriceFormatter
            price={retailPrice}
            inline
            color={theme.palette['color/text/subtle']}
            label={<FormattedMessage id="ARTICLE.RETAIL" values={{ price: '' }} />}
          />
        )}
      </Stack>
    </Stack>
  );
};
