import { isAbsoluteUrl } from 'next/dist/shared/lib/utils';
import type { ButtonSize, ButtonVariant } from '@aph/components/common/button/types';
import { FeatureToggledNextLink } from '@aph/components/common/feature-toggled-next-link';
import { Button } from '@aph/ui/components/button/button';
import { Icon } from '@aph/ui/components/icon/icon';
import { appendLeadingSlash } from '@aph/utilities/slug';

type ButtonLinkProps = {
  text: string;
  size: ButtonSize;
  variant: ButtonVariant;
  link: string;
  isExternalLink?: boolean;
};

export const ButtonLink = ({ text, size, variant, link, isExternalLink }: ButtonLinkProps) => {
  const href = isAbsoluteUrl(link) ? link : appendLeadingSlash(link);

  return (
    <Button
      data-pw="button-link"
      size={size}
      variant={variant}
      formTarget={isExternalLink ? '_blank' : undefined}
      asChild
    >
      <FeatureToggledNextLink
        href={href}
        target={isExternalLink ? '_blank' : undefined}
        rel={isExternalLink ? 'noopener noreferrer' : undefined}
      >
        {text}
        {isExternalLink ? <Icon name="BoxArrow" size={size} /> : null}
      </FeatureToggledNextLink>
    </Button>
  );
};
