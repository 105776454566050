import { useEffect, useRef, useState } from 'react';
import type { DebounceSettings } from 'lodash';
import debounce from 'lodash/debounce';

/**
 * Returns a debounced value that updates after a specified delay (500ms is the default).
 * @param value The value to debounce.
 * @param delay The delay in milliseconds before the value updates. Defaults to 500ms.
 * @param options Additional options for the debounce function.
 * @returns The debounced value.
 */
export function useDebounce<TValue>(
  value: TValue,
  delay?: number,
  options?: DebounceSettings,
): TValue {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const debouncedFn = useRef(debounce((v) => setDebouncedValue(v), delay ?? 500, options)).current;

  useEffect(() => {
    debouncedFn(value);
    return debouncedFn.cancel;
  }, [value, delay, options, debouncedFn]);

  return debouncedValue;
}
