import React from 'react';
import { Dialog, SvgIcon } from '@mui/material';
import { Stack, useMediaQuery } from '@mui/system';
import { FormattedMessage } from 'react-intl';
import { Button } from '@aph/ui/components/button/button';
import { Typography } from '@aph/ui/components/typography/typography';
import theme from '~/styles/theme';
import { CloseIcon } from './close-icon.component';

export const DiklofenalDialog: React.FC<{
  showDialog: boolean;
  onClose: (confirmed: boolean) => void;
}> = ({ showDialog, onClose }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          alignItems: { xs: 'flex-end', sm: 'center' },
        },
      }}
      PaperProps={{
        sx: {
          maxWidth: { xs: '100vw', sm: '724px' },
          px: { xs: 2, sm: 4 },
          pt: 6,
          pb: 4,
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
          borderBottomLeftRadius: { xs: 0, sm: 24 },
          borderBottomRightRadius: { xs: 0, sm: 24 },
          m: { xs: 0, sm: 6 },
        },
      }}
      open={showDialog}
      onClose={() => onClose(false)}
    >
      <SvgIcon
        fontSize="medium"
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          top: { xs: theme.spacing(2), sm: theme.spacing(4) },
          right: { xs: theme.spacing(2), sm: theme.spacing(4) },
          color: theme.palette['color/text/action'],
        }}
        onClick={() => onClose(false)}
      >
        <CloseIcon />
      </SvgIcon>

      <Typography color="text-brand" typography="headingExtraLarge" asChild>
        <h1>
          <FormattedMessage id="ADD_ARTICLE_TO_CART.DIKLOFENAK.TITLE" />
        </h1>
      </Typography>

      <Typography className="xs:mb-5 whitespace-pre-line sm:mb-3">
        <FormattedMessage id="ADD_ARTICLE_TO_CART.DIKLOFENAK.TEXT" />
      </Typography>

      <Stack justifyContent="flex-end" flexDirection={{ xs: 'column-reverse', sm: 'row' }} gap={1}>
        <Button
          data-pw="diklofenak-cancel-button"
          variant="tertiary"
          size={isMobile ? 'large' : 'medium'}
          width={isMobile ? 'full' : 'auto'}
          onClick={() => onClose(false)}
        >
          <FormattedMessage id="ADD_ARTICLE_TO_CART.DIKLOFENAK.CANCEL" />
        </Button>
        <Button
          data-pw="diklofenak-confirm-button"
          variant="primary"
          size={isMobile ? 'large' : 'medium'}
          width={isMobile ? 'full' : 'auto'}
          onClick={() => onClose(true)}
          data-testid="DIKLOFENAK.CONFIRM"
        >
          <FormattedMessage id="ADD_ARTICLE_TO_CART.DIKLOFENAK.CONFIRM" />
        </Button>
      </Stack>
    </Dialog>
  );
};
