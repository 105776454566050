import React from 'react';
import { Box, Stack } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { parseEventTree, sendMainNavigationEvent } from '@aph/components/gtm/events/navigation-gtm';
import { sendProfileMenuEvent } from '@aph/components/gtm/events/profile-menu-gtm';
import { Button } from '@aph/ui/components/button/button';
import { Icon } from '@aph/ui/components/icon/icon';
import { FeatureToggledNextLink } from '../feature-toggled-next-link';
import { usePrescriptionProfile } from '../header/header-prescription-profile/header-prescription-profile.container';
import { useProfile } from '../header/header-profile/header-profile.container';
import { ProfileMenuContent } from '../header/profile-menu/profile-menu-content.component';
import type { FeatureToggleableLink } from '../link-list/link-list.component';
import { LogoLink } from './logo-link.component';
import { ButtonCircle } from '@aph/ui/components/button-circle/button-circle';

export const TOP_NAVIGATION_LINKS: Array<FeatureToggleableLink> = [
  { title: 'Hem', href: '/' },
  { title: 'Tips och råd', href: '/tips-och-rad', feature: 'aph_features_tips_advice_page' },
  { title: 'Klubb Hjärtat', href: '/klubb-hjartat/mina-sidor' },
  {
    title: 'Hitta apotek',
    href: '/hitta-apotek-hjartat',
    isEpiLink: true,
  },
  {
    title: 'Kundservice',
    href: '/kundservice',
    isEpiLink: true,
  },
  {
    title: 'Produkter',
    href: '/produkter',
  },
];

export const TopNavigation = () => {
  const intl = useIntl();
  const profile = useProfile();
  const prescriptionProfile = usePrescriptionProfile();
  const [profileMenuAnchorElement, setProfileMenuAnchorElement] =
    React.useState<HTMLButtonElement | null>(null);

  const isProfileMenuOpen = Boolean(profileMenuAnchorElement);

  const handleProfileMenuClose = () => {
    setProfileMenuAnchorElement(null);
  };

  const handleLinkClick = (target: FeatureToggleableLink) =>
    sendMainNavigationEvent(parseEventTree(target.href));

  const handleProfileButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (profile.isAuthenticated) {
      setProfileMenuAnchorElement(event.currentTarget);

      sendProfileMenuEvent('Mina sidor');
    } else {
      sendProfileMenuEvent('Logga in');
      profile.login();
    }
  };

  const profileButtonMessageId = profile.isAuthenticated
    ? 'COMMON.HEADER.PROFILE.BUTTON.TEXT'
    : 'COMMON.HEADER.PROFILE.BUTTON.TEXT.LOGIN';

  return (
    <>
      <Stack
        display={{ md: 'none' }}
        direction="row"
        paddingY={1.5}
        justifyContent="space-between"
        alignItems="center"
      >
        <LogoLink />
        <Stack direction="row" gap={1.5}>
          <ButtonCircle
            icon="Pill"
            size="medium"
            text={intl.formatMessage({ id: 'COMMON.HEADER.RECIPES' })}
            onClick={prescriptionProfile.onClickButton}
          />
          <ButtonCircle
            icon="Profile"
            size="medium"
            variant="secondary"
            text={intl.formatMessage({ id: profileButtonMessageId })}
            onClick={handleProfileButtonClick}
          />
        </Stack>
      </Stack>

      <Stack
        display={{ xs: 'none', md: 'flex' }}
        direction="row"
        paddingY={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          component="ul"
          direction="row"
          gap={4}
          sx={{ marginBlockStart: 0, marginBlockEnd: 0, paddingInlineStart: 0, listStyle: 'none' }}
        >
          {TOP_NAVIGATION_LINKS.map((link) => (
            <Box component="li" key={link.href}>
              <FeatureToggledNextLink
                underline="none"
                sx={{
                  typography: (theme) => theme.typography.body1,
                  color: (theme) => theme.palette['color/text/default'],
                  '&.Mui-focusVisible': {
                    outline: (theme) => `1px solid ${theme.palette['color/border/action']}}`,
                    outlineOffset: '2px',
                  },
                }}
                href={link.href}
                forceAnchorLink={link.isEpiLink}
                featureName={link.feature}
                onClick={() => handleLinkClick(link)}
              >
                {link.title}
              </FeatureToggledNextLink>
            </Box>
          ))}
        </Stack>
        <Stack direction="row" gap={2}>
          <Button
            data-pw="top-navigation-button"
            size="small"
            onClick={prescriptionProfile.onClickButton}
          >
            <Icon size="small" name="Pill" />
            <FormattedMessage id="COMMON.HEADER.RECIPES" />
          </Button>
          <Button
            data-pw="top-navigation-button"
            size="small"
            variant="secondary"
            onClick={handleProfileButtonClick}
          >
            <Icon size="small" name="Profile" />
            <FormattedMessage id={profileButtonMessageId} />
          </Button>
        </Stack>
      </Stack>
      <ProfileMenuContent
        anchorEl={profileMenuAnchorElement}
        onClick={handleProfileMenuClose}
        open={isProfileMenuOpen}
        bonusBalanceState={profile.bonusBalance}
      />
    </>
  );
};
