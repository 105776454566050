import React from 'react';
import NextLink, { type LinkProps as NextLinkProps } from 'next/link';
import type { LinkProps } from '@mui/material';
import { Link } from '@mui/material';
import { useFeatureToggle } from '~/model/feature-toggle/useFeatureToggle';
import { getFeatureToggleName } from '../feature-toggles/get-feature-toggle-name';

// NextLinkProps href does not get properly set to the correct type, so have to explicitly set it here.
export type NextLinkHref = {
  pathname: string;
  query?: Record<string, string>;
};

export type FeatureToggledLinkProps = {
  featureName?: string;
  forceAnchorLink?: boolean;
  href: string | NextLinkHref | undefined;
  shallow?: boolean;
  children?: React.ReactNode;
};

export type FeatureToggledNextLinkProps = FeatureToggledLinkProps &
  Omit<LinkProps, 'href'> &
  Pick<NextLinkProps, 'prefetch'>;

const baseURL = process.env.NEXT_PUBLIC_BASE_URL_WEB ?? '';

const isNextLinkHref = (href: FeatureToggledLinkProps['href']): href is NextLinkHref =>
  typeof href === 'object' && 'pathname' in href;

const shouldOpenNewTab = (href: string | NextLinkHref | undefined) => {
  const link = isNextLinkHref(href) ? href.pathname : href;
  const isAbsolutPath = /^(?:\w+:)?\/\/([^\s.]+\.\S{2})\S*$/;
  return link && isAbsolutPath.test(link) && !link?.includes(baseURL);
};

export const FeatureToggledNextLink = React.forwardRef<
  React.ElementRef<typeof Link>,
  FeatureToggledNextLinkProps
>(({ featureName, href, prefetch, forceAnchorLink, ...props }, forwardedRef) => {
  const hrefUrl = isNextLinkHref(href) ? href.pathname : href;
  const featureToggleName = getFeatureToggleName(featureName, hrefUrl);
  const { isEnabled, feature } = useFeatureToggle(featureToggleName);

  const isUsingFeatureToggle = Boolean(feature);

  const openNewTab = shouldOpenNewTab(href);

  if (!href) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{props.children}</>;
  }

  if (forceAnchorLink || (isUsingFeatureToggle && !isEnabled) || openNewTab) {
    return (
      <Link
        ref={forwardedRef}
        {...props}
        href={hrefUrl}
        target={openNewTab ? '_blank' : undefined}
      />
    );
  }

  return (
    <NextLink href={href} passHref prefetch={prefetch} legacyBehavior>
      <Link ref={forwardedRef} {...props} />
    </NextLink>
  );
});
