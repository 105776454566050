import { Box, Grid, Stack, SvgIcon } from '@mui/material';
import {
  CONTENT_WIDTH,
  GRID_GUTTER,
  PageLayoutContainer,
  SIDEBAR_WIDTH,
} from '@aph/components/layouts/page-layout-container';
import ApotekLogo from './apotek_logo.svg';
import { FooterLinks } from './footer-links/footer-links.component';
import FooterWave from './footer_wave.svg';
import { footerLinks, footerShortcutLinks } from './links/links-data';
import { PartnersAndLegal } from './partners-and-legal/partners-and-legal.component';
import { ShortcutLinks } from './shortcut-links/shortcut-links.component';
import { SocialIcons } from './social-icons/social-icons.component';

export const Footer = () => {
  return (
    <Box marginTop={4} width="100%">
      <SvgIcon
        viewBox="0 0 1440 102"
        preserveAspectRatio="none"
        sx={{ height: 'auto', width: '100%', marginBottom: { xs: -2, sm: -2 } }}
      >
        <FooterWave />
      </SvgIcon>
      <Stack
        position="relative"
        width="100%"
        flexDirection="column"
        minHeight="732px"
        height="fit-content"
        alignItems="center"
        paddingTop={2}
        bgcolor={(theme) => theme.palette['color/background/inverse']}
      >
        <footer
          style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}
        >
          <PageLayoutContainer>
            <Stack direction={{ sm: 'column', md: 'row' }} width="100%">
              <Box paddingLeft={1} minWidth={SIDEBAR_WIDTH} marginRight={GRID_GUTTER}>
                <SvgIcon inheritViewBox sx={{ height: 50, width: 196 }}>
                  <ApotekLogo />
                </SvgIcon>
              </Box>
              <Box
                maxWidth={CONTENT_WIDTH}
                height="fit-content"
                display="grid"
                rowGap={{ xs: 0, sm: 2 }}
                columnGap={{ xs: 0, sm: 2 }}
                width="100%"
                gridTemplateColumns={{
                  xs: 'repeat(1, minmax(0, 1fr))',
                  sm: 'repeat(3, minmax(0, 1fr))',
                  lg: 'repeat(4, minmax(0, 1fr))',
                  xl: 'repeat(6, minmax(0, 1fr))',
                }}
              >
                <Grid
                  item
                  gridColumn={{
                    lg: '1',
                    xl: '1 / span 3',
                  }}
                  display={{ xs: 'none', sm: 'none', lg: 'block' }}
                />
                <Grid item marginLeft={1}>
                  <ShortcutLinks links={footerShortcutLinks} />
                </Grid>
                <Grid item>
                  <Stack spacing={{ xs: 0, sm: 4 }}>
                    <FooterLinks linksData={footerLinks.about} />
                    <FooterLinks linksData={footerLinks.services} />
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack spacing={{ xs: 0, sm: 4 }}>
                    <FooterLinks linksData={footerLinks.help} />
                    <FooterLinks linksData={footerLinks.popularCategories} />
                  </Stack>
                </Grid>
              </Box>
            </Stack>
            <Box marginTop="auto" paddingX={1}>
              <SocialIcons />
              <PartnersAndLegal />
            </Box>
          </PageLayoutContainer>
        </footer>
      </Stack>
    </Box>
  );
};
