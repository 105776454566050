import { useQueryClient } from '@tanstack/react-query';
import { createQuery } from 'react-query-kit';
import { notEmpty } from '@aph/utilities/not-empty';
import { appendLeadingSlash } from '@aph/utilities/slug';
import type { NavigationItem } from '~/contentful/api/ContentApiClient';
import { ContentApiClient } from '~/contentful/api/ContentApiClient';

interface GetCategoryTreeOptions {
  slug?: string;
  levels?: number;
}

export interface ProductCategoryNavigationItem {
  href: string;
  title: string;
  children: ProductCategoryNavigationItem[];
  hasChildren: boolean;
}

export const useGetProductCategoryNavigation = createQuery({
  queryKey: ['product-category-navigation'],
  fetcher: async (variables: GetCategoryTreeOptions, { meta }) => {
    const contentApiClient = new ContentApiClient({ headers: meta?.headers });
    const data = await contentApiClient.getNavigation({
      type: 'Category',
      slug: variables?.slug,
      levels: variables?.levels,
    });

    const productCategories =
      data.find((item) => item.slug?.replaceAll('/', '').toLowerCase() === 'produkter')?.children ??
      [];
    return productCategories.reduce<ProductCategoryNavigationItem[]>(
      isValidProductCategoryMenuItem,
      [],
    );
  },
  use: [
    // this is a middleware function that tries to get navigation items from the RQ cache and use them as placeholder data (e.g. don't cache)
    // we can pre-fetch parts of the navigation tree on server side so we'll use that for initial rendering if possible
    function middleware(useQueryNext) {
      return (options, queryClient) => {
        const client = useQueryClient(queryClient);

        const query = client.getQueryCache().find({ queryKey: options.queryKey, exact: false });
        return useQueryNext(
          {
            ...options,
            placeholderData: query?.state.data as ProductCategoryNavigationItem[],
          },
          queryClient,
        );
      };
    },
  ],
  staleTime: Infinity,
});

const isValidProductCategoryMenuItem = (
  categories: ProductCategoryNavigationItem[],
  category: NavigationItem,
): ProductCategoryNavigationItem[] => {
  if (notEmpty(category.slug) && notEmpty(category.title)) {
    return [
      ...categories,
      {
        href: appendLeadingSlash(category.slug),
        title: category.title,
        hasChildren: category.hasChildren ?? false,
        children:
          category.children?.reduce<ProductCategoryNavigationItem[]>(
            isValidProductCategoryMenuItem,
            [],
          ) ?? [],
      },
    ];
  }

  return categories;
};
