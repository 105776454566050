import { FeatureToggledNextLink } from '@aph/components/common/feature-toggled-next-link';
import { Button } from '@aph/ui/components/button/button';
import type { IJumpLinkComponentFields } from '~/contentful/types';

type JumpHyperLinkProps = Pick<IJumpLinkComponentFields, 'identifier'>;

export const JumpHyperLink: React.FC<{ children?: React.ReactNode } & JumpHyperLinkProps> = ({
  identifier,
  children,
}) => (
  <Button data-pw="jump-hyperlink" variant="tertiary" asChild>
    <FeatureToggledNextLink href={`#${identifier}`}>{children}</FeatureToggledNextLink>
  </Button>
);
