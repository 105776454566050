import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link as MuiLink,
  Skeleton,
  Stack,
  SvgIcon,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { parseEventTree, sendMainNavigationEvent } from '@aph/components/gtm/events/navigation-gtm';
import { useMatchRoute } from '@aph/hooks/use-match-route/use-match-route';
import Caret from '@aph/icons/caret-nav.svg';
import { Typography } from '@aph/ui/components/typography/typography';
import {
  type ProductCategoryNavigationItem,
  useGetProductCategoryNavigation,
} from './use-get-product-category-navigation';

type CategoriesAccordionProps = {
  categories: ProductCategoryNavigationItem[];
  level?: number;
  parent?: ProductCategoryNavigationItem;
};

const CategoriesAccordion = ({ categories, level = 1, parent }: CategoriesAccordionProps) => {
  const isMatch = useMatchRoute();
  const router = useRouter();

  const shouldRenderViewAllLink = level > 1 && parent && !isMatch(parent.href, { exact: true });

  function handleSelectCategory(href: string, options?: { shouldNavigate?: boolean }) {
    sendMainNavigationEvent(parseEventTree(href));
    if (options?.shouldNavigate) {
      router.push(href);
    }
  }

  return (
    <Stack
      gap={level > 1 ? 1 : 2}
      direction="column"
      component="ul"
      sx={{
        marginBlockStart: 0,
        marginBlockEnd: 0,
        paddingInlineStart: 0,
        listStyleType: 'none',
      }}
    >
      {shouldRenderViewAllLink ? (
        <Typography
          asChild
          typography="body"
          className="text-default hover:bg-default focus-visible:outline-action-dark cursor-pointer rounded-md px-1 py-0.5"
        >
          <li
            tabIndex={0}
            role="menuitem"
            onClick={() => handleSelectCategory(parent.href, { shouldNavigate: true })}
            onKeyDown={() => handleSelectCategory(parent.href, { shouldNavigate: true })}
            aria-label={parent.title}
          >
            <FormattedMessage id="CATEGORY_NAVIGATION.VIEW_ALL" />
          </li>
        </Typography>
      ) : null}

      {categories?.map((category) => {
        const hasSubLevels = category.children.length >= 1 || category.hasChildren;
        const isActive = isMatch(category.href);

        if (hasSubLevels) {
          return (
            <Accordion
              key={category.href}
              defaultExpanded={isActive}
              component="li"
              square
              disableGutters
              sx={{
                typography: (theme) => theme.typography.body1,
                color: (theme) => theme.palette['color/text/default'],
                boxShadow: 'none',
                '&:before': {
                  content: 'none',
                },
              }}
            >
              <AccordionSummary
                disableRipple
                expandIcon={<SvgIcon inheritViewBox component={Caret} sx={{ fontSize: '16px' }} />}
                sx={{
                  borderRadius: 2,
                  paddingX: 1,
                  paddingY: 0.5,
                  minHeight: 'unset',

                  '&.Mui-expanded': {
                    fontWeight: (theme) => theme.typography.fontWeightBold,
                    color: (theme) =>
                      level === 1
                        ? theme.palette['color/text/inverse-dark']
                        : theme.palette['color/text/action-dark'],
                    background: (theme) =>
                      // eslint-disable-next-line no-nested-ternary
                      level === 1
                        ? theme.palette['color/background/action-dark']
                        : hasSubLevels
                          ? theme.palette['color/background/visual']
                          : 'inherit',
                  },

                  '&:hover:not(.Mui-expanded)': {
                    background: (theme) => theme.palette['color/background/default'],
                  },

                  '&.Mui-focusVisible:not(.Mui-expanded)': {
                    border: (theme) => `1px solid ${theme.palette['color/border/action-dark']}`,
                    background: 'transparent',
                  },

                  '& .MuiAccordionSummary-content': {
                    margin: 'unset',
                  },
                  '& .MuiAccordionSummary-expandIconWrapper': {
                    transform: 'rotate(-90deg)',
                    marginLeft: 1,
                    alignSelf: 'flex-start',
                    position: 'relative',
                    top: '3px',
                    color: (theme) => theme.palette['color/border/default'],
                    '&.Mui-expanded': {
                      transform: 'rotate(0deg)',
                      color: 'inherit',
                    },
                  },
                }}
              >
                <a
                  tabIndex={-1}
                  href={category.href}
                  className="pointer-events-none no-underline"
                  onClick={(event) => event.preventDefault()}
                >
                  {category.title}
                </a>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: 'unset',
                  marginLeft: 1,
                  marginTop: 1,
                }}
              >
                <CategoriesAccordion
                  categories={category.children}
                  level={level + 1}
                  parent={category}
                />
              </AccordionDetails>
            </Accordion>
          );
        }

        return (
          <Box component="li" key={category.href} sx={{ overflowAnchor: 'none' }}>
            <MuiLink
              component={Link}
              aria-current={isActive ? 'page' : undefined}
              href={category.href}
              onClick={() => handleSelectCategory(category.href)}
              prefetch={false}
              underline="none"
              paddingX={1}
              paddingY={0.5}
              borderRadius={2}
              display="flex"
              sx={[
                {
                  typography: (theme) => theme.typography.body1,
                  color: (theme) => theme.palette['color/text/default'],
                  '&:focus-visible': {
                    outlineColor: (theme) => theme.palette['color/border/action-dark'],
                  },
                },

                isActive
                  ? {
                      color: (theme) => theme.palette['color/text/action-dark'],
                      fontWeight: (theme) => theme.typography.fontWeightBold,
                    }
                  : {
                      '&:hover': {
                        background: (theme) => theme.palette['color/background/default'],
                      },
                    },
              ]}
            >
              {category.title}
            </MuiLink>
          </Box>
        );
      })}
    </Stack>
  );
};

export const ProductCategoryNavigation = () => {
  const { isLoading, data: categories = [] } = useGetProductCategoryNavigation();
  const router = useRouter();

  if (isLoading) {
    return (
      <>
        <Skeleton variant="text" height={22} />
        <Skeleton variant="text" height={22} />
        <Skeleton variant="text" height={22} />
      </>
    );
  }

  return (
    <CategoriesAccordion
      categories={categories}
      // using the router as a key to cause a re-render on navigation
      // ref: https://nextjs.org/docs/pages/api-reference/functions/use-router#resetting-state-after-navigation
      key={router.asPath}
    />
  );
};
