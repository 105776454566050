import type { Theme } from '@mui/material';
import type { ButtonSize, ButtonStyles, ButtonVariant } from './types';

const BUTTON_SIZE = {
  large: 48,
  medium: 40,
  small: 32,
};

const FONT_SIZE = {
  large: '18px',
  medium: '16px',
  small: '14px',
};

export const getButtonSize = (size: ButtonSize = 'medium'): number => BUTTON_SIZE[size];

export const getFontSize = (size: ButtonSize = 'medium') => FONT_SIZE[size];

export const getButtonVariantColors = (variant: ButtonVariant, theme: Theme): ButtonStyles => {
  switch (variant) {
    case 'secondary':
      return {
        default: {
          background: theme.palette['color/background/action-inverse'],
          color: theme.palette['color/text/action'],
          border: '1px solid transparent',
          borderColor: theme.palette['color/border/action'],
        },
        hover: {
          borderColor: theme.palette['color/border/action-dark'],
          color: theme.palette['color/text/action-dark'],
        },
        disabled: {
          borderColor: theme.palette['color/border/default'],
          color: theme.palette['color/text/action-subtle'],
        },
        focus: {
          outline: `2px solid ${theme.palette['color/border/action']}`,
          outlineOffset: '2px',
        },
      };
    case 'tertiary':
      return {
        default: {
          background: 'transparent',
          color: theme.palette['color/text/action'],
        },
        hover: {
          color: theme.palette['color/text/brand'],
        },
        disabled: {
          color: theme.palette['color/text/action-subtle'],
        },
        focus: {
          outline: `1px solid ${theme.palette['color/border/action-dark']}`,
        },
      };
    case 'primary':
    default:
      return {
        default: {
          background: theme.palette['color/background/action'],
          color: theme.palette['color/text/inverse'],
        },
        hover: {
          background: theme.palette['color/background/action-dark'],
        },
        disabled: {
          background: theme.palette['color/background/action-subtle'],
          color: theme.palette['color/text/action-subtle'],
          opacity: 0.5,
        },
        focus: {
          outline: `2px solid ${theme.palette['color/border/action']}`,
          outlineOffset: '2px',
        },
      };
  }
};
