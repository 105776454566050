import React from 'react';
import { Box, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Button } from '@aph/ui/components/button/button';
import { Icon } from '@aph/ui/components/icon/icon';
import type { IArticleReferenceResultCollection } from '~/articles/generated/ArticlesClient';
import { FeatureToggledNextLink } from '../../feature-toggled-next-link';
import { ProductCard } from '../../product-card/product-card';
import type { QueryResult } from './types';
import { Typography } from '@aph/ui/components/typography/typography';

interface ProducSearchResultsProps extends QueryResult<IArticleReferenceResultCollection> {
  query: string | undefined;
}
export const ProducSearchResults: React.FC<ProducSearchResultsProps> = ({
  isLoading,
  data,
  query,
}) => {
  let products = null;
  let showmore = null;

  if (isLoading) {
    products = (
      <>
        <ProductCard variant="searchresult" loading />
        <ProductCard variant="searchresult" loading />
        <ProductCard variant="searchresult" loading />
        <ProductCard variant="searchresult" loading />
      </>
    );
  } else {
    products = data?.results?.map((article, index) => (
      <Box
        component="li"
        key={article.articleCode}
        position="relative"
        sx={{
          '&:not(:last-of-type)::after': {
            content: "''",
            border: '1px solid',
            borderColor: (theme) => theme.palette['color/border/subtle'],
            width: (theme) => `calc(100% + (${theme.spacing(2)} * 2))`,
            position: 'absolute',
            bottom: (theme) => `calc(-1 * ${theme.spacing(2)})`,
            left: (theme) => `calc(-1 * ${theme.spacing(2)})`,
            right: (theme) => theme.spacing(2),
          },
        }}
      >
        <ProductCard
          variant="searchresult"
          article={article}
          index={index + 1}
          listName="sökresultat"
        />
      </Box>
    ));

    if ((data?.results?.length ?? 0) < 1) {
      products = (
        <Typography>
          <FormattedMessage id="COMMON.HEADER.SEARCH.NOHITS" />
        </Typography>
      );
    }

    if ((data?.resultsCount ?? 0) > (data?.results?.length ?? 0)) {
      showmore = (
        <Box marginTop={3} display="flex">
          <Button data-pw="product-search-results-button" variant="secondary" size="small" asChild>
            <FeatureToggledNextLink href={`/soksida/?query=${query}`}>
              <FormattedMessage
                id="COMMON.HEADER.SEARCH.HITS"
                values={{ total: data?.resultsCount }}
              />
              <Icon name="ArrowRight" size="small" />
            </FeatureToggledNextLink>
          </Button>
        </Box>
      );
    }
  }

  return (
    <>
      <Stack
        direction="column"
        rowGap={4}
        component="ol"
        sx={{ marginBlockStart: 0, marginBlockEnd: 0, paddingInlineStart: 0, listStyle: 'none' }}
      >
        {products}
      </Stack>
      {showmore}
    </>
  );
};
