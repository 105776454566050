import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { logger } from '~/logging';
import { type BonusVouchersState, initialState } from './bonus-vouchers-state';
import { apiGetBonusVouchers } from './bonus-vouchers.api';

interface BonusVouchersStore {
  bonusVouchers: BonusVouchersState;
  actions: {
    getBonusVouchers: () => void;
  };
}

export const useBonusVouchersStore = create<BonusVouchersStore>()(
  devtools<BonusVouchersStore>(
    (set) => ({
      bonusVouchers: initialState,
      actions: {
        getBonusVouchers: async () => {
          const correlationId = uuidv4();
          set(
            {
              bonusVouchers: {
                ...initialState,
                loading: true,
                error: false,
                updated: Date.now(),
              },
            },
            false,
            'Fetching bonusVouchers',
          );
          try {
            const response = await apiGetBonusVouchers(correlationId);
            logger.info(
              `getBonusVouchersSaga, Correlation ID: ${correlationId} was fetched successfully`,
            );
            set(
              {
                bonusVouchers: {
                  ...initialState,
                  data: response || initialState.data,
                  loading: false,
                  error: false,
                  updated: Date.now(),
                },
              },
              false,
              'Fetching bonusVouchers success',
            );
          } catch (event) {
            const { userMessage, status, httpStatusCode } = event as {
              userMessage: string;
              status: string;
              httpStatusCode: number;
            };
            const errorMessage = `${
              userMessage || 'Något gick fel! Tyvärr kan inte denna del visas just nu'
            }`;
            logger.error(
              `getBonusVouchersSaga, Correlation ID: ${correlationId} failed to fetch | errorMessage: ${errorMessage} | status: ${status} | httpStatusCode: ${httpStatusCode}`,
            );
            set(
              {
                bonusVouchers: {
                  ...initialState,
                  error: true,
                  errorMessage,
                  updated: Date.now(),
                },
              },
              false,
              'Fetching bonusVouchers failed',
            );
          }
        },
      },
    }),
    {
      name: 'UseBonusVouchers',
      anonymousActionType: 'UseBonusVouchers',
      enabled: process.env.NODE_ENV === 'development',
    },
  ),
);

export const useBonusVouchersActions = () => useBonusVouchersStore((state) => state.actions);
