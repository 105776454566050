import React, { useCallback, useEffect, useState } from 'react';
import type { CartEvent } from '@aph/components/gtm/events/cart-gtm';
import { sendAddToCartEvent, sendRemoveFromCartEvent } from '@aph/components/gtm/events/cart-gtm';
import { createRxEventData } from '@aph/components/gtm/helpers/cart-rx';
import { useCartActions } from '~/model/cart/cart.store';
import { CostType } from '~/services/generated/PresentationClient';
import type { CostDetail, LineItem } from '~/services/generated/PurchaseClient';
import { MiniCartLineItemComponent } from './mini-cart-line-item.component';

export const MiniCartLineItemContainer: React.FC<{
  lineItem: LineItem;
  cartId: string;
  cartLoading: boolean;
}> = ({ lineItem, cartId, cartLoading }) => {
  const [quantity, setQuantity] = useState(0);
  const [firstUpdated, setFirstUpdated] = useState(false);

  const { updateCart, removeLineItem, getPrescriptionDetails } = useCartActions();

  const priceDetails: CostDetail[] | undefined = lineItem?.priceDetails; // skicka in istället
  const unitPrice = priceDetails?.find((i) => i.type === CostType.OriginalUnitPrice)?.value;

  useEffect(() => {
    if (!firstUpdated) {
      const initialQuantity = lineItem?.quantity || 0;
      setQuantity(initialQuantity);
      setFirstUpdated(true);
    }
  }, [lineItem, setQuantity, firstUpdated, setFirstUpdated, priceDetails]);

  useEffect(() => {
    if (!lineItem.quantity) {
      return;
    }

    if (!cartLoading) {
      setQuantity(lineItem.quantity);
    }
  }, [lineItem, setQuantity, cartLoading]);

  const handleOnChangeQuantity = useCallback(
    async (oldQuantity: number, newQuantity: number) => {
      if (lineItem) {
        const prescriptionDetails = lineItem.articleCode
          ? getPrescriptionDetails(lineItem.articleCode)
          : null;
        const isRx = prescriptionDetails != null;

        const eventData: CartEvent = isRx
          ? createRxEventData(prescriptionDetails, oldQuantity, newQuantity)
          : {
              name: lineItem.title,
              id: lineItem.articleCode,
              price: unitPrice,
              oldQuantity,
              newQuantity,
              listName: 'Produkt',
            };
        if (newQuantity > oldQuantity) {
          sendAddToCartEvent(eventData);
        } else {
          sendRemoveFromCartEvent(eventData);
        }
      }
      if (lineItem.id) {
        await updateCart({
          cartId,
          quantity: newQuantity,
          lineItemId: lineItem.id,
          articleCode: lineItem.articleCode || '',
        }).catch(() => setQuantity(oldQuantity));
      }
    },
    [lineItem, getPrescriptionDetails, unitPrice, updateCart, cartId],
  );

  const onRemoveLineItem = async () => {
    if (lineItem?.id) {
      const prescriptionDetails = lineItem.articleCode
        ? getPrescriptionDetails(lineItem.articleCode)
        : null;
      const isRx = prescriptionDetails != null;

      await removeLineItem(lineItem.id);

      const eventData: CartEvent = isRx
        ? createRxEventData(prescriptionDetails, quantity, 0)
        : {
            name: lineItem.title,
            id: lineItem.articleCode,
            price: unitPrice,
            oldQuantity: quantity,
            newQuantity: 0,
            listName: 'Produkt',
          };
      sendRemoveFromCartEvent(eventData);
    }
  };

  return (
    <MiniCartLineItemComponent
      title={lineItem.title || ''}
      articleUrl={lineItem.articleUrl}
      imageUrl={lineItem.imageUrl}
      image={lineItem.image}
      setQuantity={setQuantity}
      updateQuantity={handleOnChangeQuantity}
      onRemoveLineItem={onRemoveLineItem}
      quantity={quantity}
      maxQuantity={lineItem.maxQuantity}
      priceDetails={priceDetails}
      promotions={lineItem.hvItemProperties?.promotions}
      rxItem={!!lineItem.rxItemProperties}
    />
  );
};
