import { container } from 'tsyringe';
import { logger } from '~/logging';
import type { BonusVouchersV4 } from '~/services/generated/CustomerClient';
import { Client as CustomerClient } from '~/services/generated/CustomerClient';

export const apiGetBonusVouchers = async (correlationId: string): Promise<BonusVouchersV4> => {
  const client = container.resolve(CustomerClient);
  const response = await client.getVouchersV4(correlationId, 'aphTinman', undefined);
  logger.info(`Correlation ID: ${correlationId} getVouchersV4() was called`);
  return response;
};
