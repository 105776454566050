import React, { forwardRef, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { formatProductUrl } from '@aph/utilities/format-product-url';
import type { Article } from '~/articles/generated/ArticlesClient';
import { Button, type ButtonProps } from '@aph/components/common/button';
import { DiklofenalDialog } from './diklofenak-dialog.component';

export type AddArticleToCartButtonProps = {
  onClick: () => void;
} & Pick<ButtonProps, 'loading' | 'size' | 'disabled'> &
  Pick<
    Article,
    | 'hasVariants'
    | 'activeSubstances'
    | 'isAvailableForPurchase'
    | 'requiresPrescriptionForPurchase'
    | 'articleUrlSegment'
  >;

export const AddArticleToCartButton = forwardRef<HTMLButtonElement, AddArticleToCartButtonProps>(
  (
    {
      activeSubstances,
      isAvailableForPurchase,
      requiresPrescriptionForPurchase,
      onClick,
      size = 'medium',
      disabled,
      loading,
      hasVariants,
      articleUrlSegment,
      ...props
    }: AddArticleToCartButtonProps,
    forwardedRef,
  ) => {
    const [showDiklofenakDialog, setShowDiklofenakDialog] = useState(false);

    const textId = useMemo(() => {
      if (hasVariants) {
        if (!isAvailableForPurchase) {
          return 'ADD_ARTICLE_TO_CART.BUTTON.NOT.BUYABLE';
        }
        return 'ADD_ARTICLE_TO_CART.BUTTON.HAS_VARIANTS';
      }
      if (requiresPrescriptionForPurchase) {
        return 'ADD_ARTICLE_TO_CART.BUTTON.BUY_VIA_PRESCRIPTION';
      }
      if (isAvailableForPurchase) {
        return 'ADD_ARTICLE_TO_CART.BUTTON.BUYABLE';
      }
      return 'ADD_ARTICLE_TO_CART.BUTTON.NOT.BUYABLE';
    }, [hasVariants, requiresPrescriptionForPurchase, isAvailableForPurchase]);

    const href = useMemo(() => {
      if (hasVariants) {
        return formatProductUrl(articleUrlSegment);
      }
      if (requiresPrescriptionForPurchase) {
        return `${process.env.NEXT_PUBLIC_BASE_URL_WEB}/mina-recept/receptlistan/`;
      }
      return undefined;
    }, [hasVariants, requiresPrescriptionForPurchase, articleUrlSegment]);

    const onClickProxy = () => {
      const containsDiklofenak = (activeSubstances || [])
        .map((item) => item.toLocaleLowerCase())
        .includes('diklofenak');

      if (containsDiklofenak) {
        setShowDiklofenakDialog(true);
      } else if (!href) {
        onClick();
      }
    };

    const onDialogClose = (confirmed: boolean) => {
      if (confirmed) {
        onClick();
      }
      setShowDiklofenakDialog(false);
    };

    return (
      <>
        <Button
          data-pw="add-article-to-cart-button"
          data-testid="AddArticleToCartButton"
          ref={forwardedRef}
          href={href}
          disabled={(!isAvailableForPurchase && !requiresPrescriptionForPurchase) || disabled}
          onClick={onClickProxy}
          size={size}
          fullWidth
          loading={loading}
          {...props}
        >
          <FormattedMessage id={textId} />
        </Button>
        <DiklofenalDialog showDialog={showDiklofenakDialog} onClose={onDialogClose} />
      </>
    );
  },
);
