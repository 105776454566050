import { sendEcommerce } from '../useGtm';

type CartTrackingEvent = {
  item_id?: string;
  item_name?: string;
  item_brand?: string;
  item_category?: string;
  item_list_name?: string;
  price?: number;
  quantity: number;
  is_sensitive?: 'yes' | 'no';
};

export type CartEvent = {
  id?: string;
  name?: string;
  brand?: string;
  category?: string;
  listName?: string;
  price?: number;
  newQuantity: number;
  oldQuantity: number;
  isSensitive?: boolean;
};

const mapIsSensitive = (event: CartEvent) => {
  if (event.isSensitive === undefined) return undefined;

  return event.isSensitive ? 'yes' : 'no';
};

const mapToCartTrackingEvent = (event: CartEvent): CartTrackingEvent => ({
  item_id: event.id,
  item_name: event.name,
  item_brand: event.brand,
  item_category: event.category,
  item_list_name: event.listName,
  price: event.price,
  quantity: Math.abs(event.oldQuantity - event.newQuantity),
  is_sensitive: mapIsSensitive(event),
});

export const sendAddToCartEvent = (event: CartEvent) => {
  sendEcommerce({
    event: 'add_to_cart',
    ecommerce: {
      items: [mapToCartTrackingEvent(event)],
    },
  });
};

export const sendRemoveFromCartEvent = (event: CartEvent) => {
  sendEcommerce({
    event: 'remove_from_cart',
    ecommerce: {
      items: [mapToCartTrackingEvent(event)],
    },
  });
};
