import React from 'react';
import { Box, Stack } from '@mui/material';
import { useMatchRoute } from '@aph/hooks/use-match-route/use-match-route';
import { FeatureToggledNextLink } from '../feature-toggled-next-link';

export interface FeatureToggleableLink {
  href: string;
  isEpiLink?: boolean;
  feature?: string;
  title: string;
}

export type LinkListProps = {
  links: FeatureToggleableLink[];
  variant: 'primary' | 'secondary';
  onLinkClick?: (target: FeatureToggleableLink) => void;
  typography?: 'body1' | 'bodyBig';
  isActiveStrategy?: 'partial' | 'exact';
};

export const LinkList = ({
  links,
  variant,
  onLinkClick,
  typography = 'body1',
  isActiveStrategy = 'exact',
}: LinkListProps) => {
  const isActiveLink = useMatchRoute();

  return (
    <Stack
      gap={1}
      direction="column"
      component="ul"
      sx={{
        marginBlockStart: 0,
        marginBlockEnd: 0,
        paddingInlineStart: 0,
        listStyleType: 'none',
      }}
    >
      {links.map((link) => (
        <Box component="li" key={link.href}>
          <FeatureToggledNextLink
            href={link.href}
            forceAnchorLink={link.isEpiLink}
            featureName={link.feature}
            className={
              isActiveLink(link.href, { exact: isActiveStrategy === 'exact' })
                ? 'active'
                : undefined
            }
            onClick={() => onLinkClick?.(link)}
            underline="none"
            paddingX={1}
            paddingY={0.5}
            borderRadius={2}
            display="flex"
            sx={[
              { wordBreak: 'break-word' },
              {
                typography: (theme) => theme.typography[typography],
                '&:hover': {
                  background: (theme) => theme.palette['color/background/default'],
                },
                '&:focus-visible': {
                  outlineColor: (theme) => theme.palette['color/border/action-dark'],
                },
              },
              variant === 'primary' && {
                color: (theme) => theme.palette['color/text/action-dark'],
                fontWeight: (theme) => theme.typography.fontWeightHeavy,

                '&.active': {
                  background: (theme) => theme.palette['color/background/visual'],
                },
              },
              variant === 'secondary' && {
                color: (theme) => theme.palette['color/text/default'],

                '&.active': {
                  background: (theme) => theme.palette['color/background/action-dark'],
                  color: (theme) => theme.palette['color/text/inverse-dark'],
                  '&:focus-visible': {
                    outlineOffset: '2px',
                  },
                },
              },
            ]}
          >
            {link.title}
          </FeatureToggledNextLink>
        </Box>
      ))}
    </Stack>
  );
};
