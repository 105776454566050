import React from 'react';
import { ButtonBase as MUIButtonBase, SvgIcon } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { FeatureToggledNextLink } from '../feature-toggled-next-link';
import { getButtonSize, getButtonVariantColors, getFontSize } from './styles';
import type { ButtonProps } from './types';

export type { ButtonProps } from './types';

export const Button = React.forwardRef<React.ElementRef<typeof MUIButtonBase>, ButtonProps>(
  (
    {
      icon,
      children,
      size = 'medium',
      variant = 'primary',
      fullWidth,
      iconPosition,
      loading = false,
      disabled = false,
      ...props
    },
    forwardedRef,
  ) => {
    const theme = useTheme();
    const style = getButtonVariantColors(variant, theme);
    const renderChildren = () => {
      let additionalChild = null;
      if (loading) {
        additionalChild = <CircularProgress color="inherit" size="1em" disableShrink />;
      } else if (icon) {
        additionalChild = (
          <SvgIcon
            inheritViewBox
            component={icon}
            sx={[
              {
                fontSize: 'inherit',
                color: 'currentColor',
              },
              size === 'large' && {
                fontSize: '20px',
              },
            ]}
          />
        );
      }
      return (
        <>
          {children}
          {additionalChild}
        </>
      );
    };

    return (
      <MUIButtonBase
        ref={forwardedRef}
        LinkComponent={FeatureToggledNextLink}
        sx={[
          {
            height: getButtonSize(size),
            fontFamily: theme.typography.fontFamily,
            fontSize: getFontSize(size),
            fontWeight: 700,
            lineHeight: '24px',
            gap: 0.5,
            paddingX: 2,
            paddingY: 1,
            borderRadius: 8,
          },
          {
            ...style.default,
            '&:hover': {
              '@media (hover: hover)': style.hover,
            },
            '&.Mui-disabled': style.disabled,
            '&.Mui-focusVisible': style.focus,
          },
          Boolean(fullWidth) && {
            width: '100%',
          },
          iconPosition === 'start' && {
            flexDirection: 'row-reverse',
          },
        ]}
        disabled={disabled || loading}
        disableRipple
        {...props}
      >
        {renderChildren()}
      </MUIButtonBase>
    );
  },
);

Button.displayName = 'Button';
