import Image from 'next/image';
import Link from '@mui/material/Link';

interface ImageLinkProps {
  imgSrc: string;
  imgAlt: string;
  height: number;
  width: number;
  href: string;
}

export const ImageLink = ({ imgSrc, imgAlt, height, width, href }: ImageLinkProps) => {
  return (
    <Link href={href} target="_blank">
      <Image src={imgSrc} alt={imgAlt} height={height} width={width} />
    </Link>
  );
};
