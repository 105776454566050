import { sendEcommerce, sendEvent } from '@aph/components/gtm/useGtm';
import type { IArticle } from './generated/ArticlesClient';

const getBaseData = (article: IArticle) => ({
  item_id: article.articleCode,
  item_name: article.name,
  item_brand: article.brandReferences?.at(0)?.name,
  item_category: article?.productCategoryDetails?.at(0)?.title,
  price: article.price?.webPrice,
  is_sensitive: article.isSensitive,
});

export const sendSelectItemEvent = (article: IArticle, itemListName: string, index: number) =>
  sendEcommerce({
    event: 'select_item',
    ecommerce: {
      items: [
        {
          ...getBaseData(article),
          stock_status: article.stockItem?.stockStatus,
          item_list_name: itemListName,
          index: index + 1,
        },
      ],
    },
  });

export const sendViewItemListEvent = (article: IArticle, itemListName: string, index: number) =>
  sendEcommerce({
    event: 'view_item_list',
    ecommerce: {
      items: [
        {
          ...getBaseData(article),
          item_list_name: itemListName,
          index,
        },
      ],
    },
  });

export const sendMoreCampaignsEvent = (text: string) =>
  sendEvent({
    event: 'more_campaigns',
    click_text: text,
  });

export const sendAccordionCloseEvent = (title: string) =>
  sendEvent({
    event: 'close_product_info',
    info_section: title,
  });

export const sendAccordionOpenEvent = (title: string) =>
  sendEvent({
    event: 'open_product_info',
    info_section: title,
  });
