import { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, SvgIcon, Typography } from '@mui/material';
import CaretDown from '@aph/icons/arrowhead_down.svg';
import type { FooterLinkType } from '../links/links-data';
import type { LinksProps } from './footer-links.component';
import { LinkList } from './link-list.component';

interface MobileLinksProps extends LinksProps {
  footerLinkClickEvent: (args: { link: FooterLinkType }) => void;
}
export const MobileLinks = ({ links, header, footerLinkClickEvent }: MobileLinksProps) => {
  const [linkExpanded, setLinkExpanded] = useState<boolean>(false);
  return (
    <Accordion
      disableGutters
      expanded={linkExpanded}
      onChange={() => setLinkExpanded((prev) => !prev)}
      sx={{ backgroundColor: 'transparent', boxShadow: 'none', marginLeft: 1, marginRight: 1 }}
    >
      <AccordionSummary
        sx={{
          fontSize: 28,
          padding: 0,
          borderBottom: linkExpanded
            ? 'none'
            : (theme) => `1px solid ${theme.palette['color/border/default']}`,
        }}
        expandIcon={
          <SvgIcon
            inheritViewBox
            sx={{
              height: 20,
              width: 20,
              color: (theme) => theme.palette['color/icon/inverse'],
            }}
          >
            <CaretDown />
          </SvgIcon>
        }
      >
        <Typography variant="bodyHeavy" color={(theme) => theme.palette['color/text/inverse']}>
          {header.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          paddingLeft: 0,
          borderBottom: (theme) => `1px solid ${theme.palette['color/border/default']}`,
        }}
      >
        <LinkList gap={2} links={links} onLinkClick={footerLinkClickEvent} />
      </AccordionDetails>
    </Accordion>
  );
};
