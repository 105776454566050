import React from 'react';
import { type RenderNode, documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Document } from '@contentful/rich-text-types';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { Box, Link, type TypographyProps } from '@mui/material';
import Divider from '@mui/material/Divider';
import MUITypography from '@mui/material/Typography';
import has from 'lodash/has';
import { AphAlert } from '@aph/components/common/';
import { AphAccordion } from '@aph/components/common/aph-accordion/aph-accordion.component';
import { FeatureToggledNextLink } from '@aph/components/common/feature-toggled-next-link';
import { Typography } from '@aph/ui/components/typography/typography';
import { urlify } from '@aph/utilities/slug';
import type { ICarrierFields } from '../../types';
import { AphChecklist } from '../aph-checklist/aph-checklist.component';
import { Carrier } from '../carrier.component';
import { AssetImage } from './asset/asset-image.component';
import { ButtonLink } from './button-link-component/button-link.component';
import { JumpHyperLink } from './jump-hyperlink-component/jump-hyperlink.component';
import { JumpLinkComponent } from './jump-link-component/jump-link.component';

export const isRichText = (x: Document | unknown): x is Document =>
  ['data', 'content', 'nodeType'].every((prop) => has(x, prop));

type HyperlinkProps = {
  type: 'AssetLink' | 'PlainLink' | 'EntryLink';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any;
} & Pick<Document, 'data'>;

const PlainHyperlink = (props: Omit<HyperlinkProps, 'type'>) => (
  <Hyperlink {...props} type="PlainLink" />
);

const AssetHyperlink = (props: Omit<HyperlinkProps, 'type'>) => (
  <Hyperlink {...props} type="AssetLink" />
);

const EntryHyperlinks = (props: Omit<HyperlinkProps, 'type'>) => (
  <Hyperlink {...props} type="EntryLink" />
);

const Hyperlink = (props: HyperlinkProps) => {
  const { type, data, content } = props;

  const linkText = renderRichText({
    content,
    data: {},
    nodeType: BLOCKS.DOCUMENT,
  });

  if (type === 'EntryLink') {
    const { slug } = data.target.fields;

    if (typeof slug === 'string') {
      return <FeatureToggledNextLink href={urlify(slug)}>{linkText}</FeatureToggledNextLink>;
    }

    return null;
  }
  const href = type === 'AssetLink' ? data.target.fields.file.url : data.uri;
  // Link text has to be rendered itself as rich text
  // to account for various formatting options (e.g. bold text)

  const targetOption = href.includes('apotekhjartat.se') ? '_self' : '_blank';

  return (
    <Link href={href} target={targetOption} rel="noopener noreferrer">
      {linkText}
    </Link>
  );
};

const Heading: React.FC<{ children?: React.ReactNode } & TypographyProps> = ({
  children,
  variant,
  ...props
}) => (
  <MUITypography {...props} variant={variant} margin="unset" mt={2}>
    {children}
  </MUITypography>
);

export const renderRichText = (rtd: Document, overrideNodes?: RenderNode) =>
  documentToReactComponents(rtd, {
    renderMark: {
      [MARKS.BOLD]: (text) => <b>{text}</b>,
      [MARKS.ITALIC]: (text) => <i>{text}</i>,
      [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
      [MARKS.CODE]: (text) => <code>{text}</code>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: PlainHyperlink,
      [INLINES.ASSET_HYPERLINK]: AssetHyperlink,
      [INLINES.ENTRY_HYPERLINK]: ({ data, content }, children) => {
        if (data.target.sys.contentType.sys.id === 'jumpLinkComponent') {
          const { identifier } = data.target.fields;
          return <JumpHyperLink identifier={identifier}>{children}</JumpHyperLink>;
        }
        return <EntryHyperlinks data={data} content={content} />;
      },
      // [INLINES.ENTRY_HYPERLINK]: EntryHyperlinks,

      [BLOCKS.HEADING_1]: (_, children) => <Heading variant="h1">{children}</Heading>,
      [BLOCKS.HEADING_2]: (_, children) => <Heading variant="h2">{children}</Heading>,
      [BLOCKS.HEADING_3]: (_, children) => <Heading variant="h3">{children}</Heading>,
      [BLOCKS.HEADING_4]: (_, children) => <Heading variant="h4">{children}</Heading>,
      // temporary setting h5 as a smaller variant of h4 since we haven't agreed on typography yet
      [BLOCKS.HEADING_5]: (_, children) => (
        <Heading variant="h4" fontSize={15}>
          {children}
        </Heading>
      ),
      // temporary setting h6 as a smaller variant of h4 since we haven't agreed on typography yet
      [BLOCKS.HEADING_6]: (_, children) => (
        <Heading variant="h4" fontSize={14}>
          {children}
        </Heading>
      ),
      [BLOCKS.PARAGRAPH]: (_, children) => (
        <Typography typography="body" className="my-1">
          {children}
        </Typography>
      ),
      [BLOCKS.UL_LIST]: (_, children) => (
        <ul className="ms-[1em] list-outside list-disc">{children}</ul>
      ),
      [BLOCKS.OL_LIST]: (_, children) => (
        <ol className="ms-[1em] list-outside list-decimal">{children}</ol>
      ),
      [BLOCKS.HR]: () => <Divider />,
      [BLOCKS.EMBEDDED_ASSET]: ({ data }) => {
        const { description } = data.target.fields;
        const { url, details } = data.target.fields.file;
        return (
          <AssetImage
            src={url}
            description={description}
            width={details.image?.width}
            height={details.image?.height}
          />
        );
      },

      [BLOCKS.EMBEDDED_ENTRY]: ({ data }) => {
        if (data.target.sys.contentType.sys.id === 'aphChecklist') {
          const list = data.target.fields;
          return <AphChecklist list={list} />;
        }
        if (data.target.sys.contentType.sys.id === 'carrier') {
          const carrier = data.target.fields as ICarrierFields;
          return <Carrier logo={carrier.logo}>{renderRichText(carrier.deliveryOptions)}</Carrier>;
        }
        if (data.target.sys.contentType.sys.id === 'jumpLinkComponent') {
          const { identifier } = data.target.fields;
          return <JumpLinkComponent identifier={identifier} />;
        }
        if (data.target.sys.contentType.sys.id === 'componentAlert') {
          const { title, message, severity, content } = data.target.fields;
          return (
            <Box maxWidth={521}>
              <AphAlert data-pw="rich-text-render-alert" title={title} severity={severity}>
                {content ? renderRichText(content) : message && message}
              </AphAlert>
            </Box>
          );
        }
        if (data.target.sys.contentType.sys.id === 'componentAccordion') {
          const { title, body } = data.target.fields;
          return (
            <AphAccordion
              accordionSummary={title}
              accordionDetails={renderRichText(body)}
              id={title}
            />
          );
        }
        if (data.target.sys.contentType.sys.id === 'componentButtonLink') {
          const { link, text, size, variant, isExternalLink } = data.target.fields;
          return (
            <ButtonLink
              link={link}
              size={size}
              text={text}
              variant={variant}
              isExternalLink={isExternalLink}
            />
          );
        }
        return null;
      },
      ...(overrideNodes || {}),
    },
    renderText: (text) => text.split('\n').flatMap((txt, i) => [i > 0 && <br key={i} />, txt]),
  });
