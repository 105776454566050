import Box from '@mui/material/Box';
import { OFFSET_TOP_DESKTOP, OFFSET_TOP_MOBILE } from './constants';

export const JumpLinkComponent: React.FC<{ identifier: string }> = ({ identifier }) => (
  <Box
    id={identifier}
    paddingTop={{ xs: OFFSET_TOP_MOBILE, md: OFFSET_TOP_DESKTOP }}
    marginTop={{ xs: -OFFSET_TOP_MOBILE, md: -OFFSET_TOP_DESKTOP }}
  />
);
