import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { cva } from 'class-variance-authority';
import { FeatureToggledNextLink } from '@aph/components/common/feature-toggled-next-link';
import { Icon } from '@aph/ui/components/icon/icon';
import { Typography } from '@aph/ui/components/typography/typography';
import { cn } from '@aph/ui/tailwind/cn';
import type { IInfoBarFields } from '~/contentful/types';
import { PageLayoutContainer } from '../../../components/layouts/page-layout-container';
import { RichTextComponent } from '../rich-text/richtext.component';
import { useInfoBar } from './use-info-bar';

const variations = cva('bg-action child:text-inverse child:fill-inverse', {
  variants: {
    color: {
      Green: 'bg-action-dark child:text-inverse child:fill-inverse',
      Pink: 'bg-notice child:text-notice child:fill-notice',
      Winter: 'child:text-[#D6E9F5] child:fill-[#D6E9F5] bg-[#0D6BA5]',
      Summer: 'child:text-action-dark child:fill-action-dark bg-[#FFE17F]',
      Autumn: 'child:text-[#FAE2CC] child:fill-[#FAE2CC] bg-[#B24A0A]',
      Christmas: 'child:text-[#FBD6CB] child:fill-[#FBD6CB] bg-[#850304]',
      'Black week': 'child:text-[#F6F6F6] child:fill-[#F6F6F6] bg-[#262626]',
      'Rosa bandet': 'child:text-[#FDECF2] child:fill-[#FDECF2] bg-[#CA0070]',
      Error: 'child:text-inverse child:fill-inverse bg-[#B91509]',
    },
  },
});

const InfoBarParagraph = (children: React.ReactNode) => (
  <Typography typography="subHeading">{children}</Typography>
);

export type InfoBarProps = {
  data?: IInfoBarFields | null;
};

export const InfoBar = ({ data }: InfoBarProps) => {
  const { hideInfoBar, isInfoBarHidden, globalServiceMessage } = useInfoBar();

  const content = globalServiceMessage || data;

  const isHidden = !content || isInfoBarHidden(content.slug);

  if (isHidden) {
    return null;
  }

  const { displayText, linkUrl, slug, color } = content;

  const text = (
    <RichTextComponent
      field={displayText}
      overrideNodes={{
        [BLOCKS.PARAGRAPH]: (_: unknown, children: React.ReactNode) => InfoBarParagraph(children),
      }}
    />
  );

  const isLink = Boolean(linkUrl);

  return (
    <PageLayoutContainer
      data-testid="InfoBar"
      className={cn(variations({ color }), {
        'child-hover:underline underline-offset-2': isLink,
      })}
    >
      <div className="relative m-auto flex min-h-[40px] flex-row items-center justify-center">
        {isLink ? (
          <FeatureToggledNextLink data-testid="InfoBarLink" className="flex" href={linkUrl}>
            {text}
            <Icon name="ArrowheadRight" className="ml-[4px]" size="small" />
          </FeatureToggledNextLink>
        ) : (
          text
        )}
        <Icon
          name="Close"
          size="small"
          className="absolute right-0"
          aria-label="close"
          data-testid="InfoBarClose"
          onClick={() => hideInfoBar(slug)}
        />
      </div>
    </PageLayoutContainer>
  );
};
