import React from 'react';
import { Skeleton } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@aph/ui/components/typography/typography';
import { urlify } from '@aph/utilities/slug';
import { type StoreReference } from '~/articles/generated/ArticlesClient';
import { FeatureToggledNextLink } from '../../feature-toggled-next-link';
import type { QueryResult } from './types';

type PharmacySearchResultProps = QueryResult<StoreReference[]>;
export const PharmacySearchResults: React.FC<PharmacySearchResultProps> = ({ isLoading, data }) => {
  let pharmacies = null;

  if (isLoading) {
    pharmacies = (
      <>
        <Skeleton variant="text" height={21} width={120} />
        <Skeleton variant="text" height={21} width={120} />
        <Skeleton variant="text" height={21} width={120} />
        <Skeleton variant="text" height={21} width={120} />
      </>
    );
  } else {
    pharmacies = data?.map((pharmacy) =>
      pharmacy.slug ? (
        <FeatureToggledNextLink
          featureName="aph_features_findpharmacy"
          href={urlify(pharmacy.slug)}
          className="text-default focus-visible:text-action-dark hover:text-action-dark active:text-action-darkest decoration-inherit underline-offset-2"
        >
          Apotek Hjärtat {pharmacy.storeName}
        </FeatureToggledNextLink>
      ) : null,
    );

    if ((data?.length ?? 0) < 1) {
      pharmacies = (
        <Typography>
          <FormattedMessage id="COMMON.HEADER.SEARCH.NOHITS" />
        </Typography>
      );
    }
  }

  return <div className="mb-2 flex flex-col gap-2">{pharmacies}</div>;
};
