import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Logout } from '@mui/icons-material';
import { Box, Divider, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { useIntl } from 'react-intl';
import { getStammisGroup } from '@aph/components/club-member/my-bonus/bonus/bonus.utils';
import { sendProfileMenuEvent } from '@aph/components/gtm/events/profile-menu-gtm';
import { getLogOutRedirectUrl } from '@aph/utilities/log-out/log-out.utils';
import { authRedirectConfig } from '~/contexts/auth/auth.redirect.config';
import type { BonusBalanceState } from '~/model/bonus/balance/bonus-balance-state';
import {
  useBonusVouchersActions,
  useBonusVouchersStore,
} from '~/model/bonus/vouchers/use-bonus-vouchers-store';
import { useAmIMemberStore } from '~/model/member/am-i-member/use-am-i-member-store';
import type { AdhocVoucherV4, MonthVoucherV4 } from '~/services/generated/CustomerClient';
import { OpCoTypeV4 } from '~/services/generated/CustomerClient';

const accountMenuStyle = {
  overflow: 'visible',
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  mt: 1.5,
  width: '250px',
  '& .MuiAvatar-root': {
    width: 32,
    height: 32,
    ml: -0.5,
    mr: 1,
  },
  '& a': {
    textDecoration: 'none',
  },
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 14,
    width: 10,
    height: 10,
    bgcolor: 'background.paper',
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0,
  },
};

type ProfileMenuContentProps = {
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  bonusBalanceState: BonusBalanceState;
  onClick: () => void;
};

export const ProfileMenuContent: React.FC<ProfileMenuContentProps> = ({
  open,
  anchorEl,
  bonusBalanceState,
  onClick,
}) => {
  const router = useRouter();
  const intl = useIntl();
  const { bonusVouchers } = useBonusVouchersStore();
  const { getBonusVouchers } = useBonusVouchersActions();
  const [totalAphCheckAmount, setTotalAphCheckAmount] = useState<number>();
  const stammisGroup = getStammisGroup(bonusBalanceState.data);
  const { hasNoSessionRedirect } = authRedirectConfig();

  const { amIMember } = useAmIMemberStore();
  const isMember = amIMember.data && amIMember.data.isMember;

  const sumAphValidVouchers = (
    adhocVouchers?: AdhocVoucherV4[] | undefined,
    monthVouchers?: MonthVoucherV4[] | undefined,
  ) => {
    let sum = 0;
    if (adhocVouchers) {
      adhocVouchers.forEach((adHocVoucher) => {
        adHocVoucher.vouchers?.forEach((voucher) => {
          if (voucher.validAtOpCo === OpCoTypeV4.All || voucher.validAtOpCo === OpCoTypeV4.Aph) {
            sum += voucher.voucherAmount;
          }
        });
      });
    }

    if (monthVouchers) {
      monthVouchers.forEach((monthVoucher) => {
        monthVoucher.vouchers?.forEach((voucher) => {
          if (voucher.validAtOpCo === OpCoTypeV4.All || voucher.validAtOpCo === OpCoTypeV4.Aph) {
            sum += voucher.voucherAmount;
          }
        });
      });
    }

    setTotalAphCheckAmount(sum);
  };

  useEffect(() => {
    if (totalAphCheckAmount === undefined && isMember && bonusVouchers.updated === 0) {
      getBonusVouchers();
    }
  }, [totalAphCheckAmount, isMember, bonusVouchers.updated, getBonusVouchers]);

  useEffect(() => {
    if (
      totalAphCheckAmount === undefined &&
      bonusVouchers.updated &&
      !bonusVouchers.loading &&
      bonusBalanceState.updated &&
      !bonusBalanceState.loading
    ) {
      sumAphValidVouchers(bonusVouchers.data.adhocVouchers, bonusVouchers.data.monthVouchers);
    }
  }, [
    totalAphCheckAmount,
    bonusVouchers,
    bonusVouchers.data,
    bonusVouchers.updated,
    bonusBalanceState.updated,
    bonusBalanceState.loading,
  ]);

  const menuItemClick = (url: string, title: string, isEpiLink?: boolean) => {
    sendProfileMenuEvent(title);
    if (isEpiLink) {
      window.location.href = url;
    } else {
      router.push(url);
    }
    onClick();
  };

  const onLogOut = () => {
    router.push(getLogOutRedirectUrl(hasNoSessionRedirect));
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={onClick}
      onClick={onClick}
      slotProps={{ paper: { elevation: 2, sx: accountMenuStyle } }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {isMember && (
        <MenuItem
          onClick={() =>
            menuItemClick(
              '/klubb-hjartat/mina-bonuscheckar/',
              intl.messages['COMMON.PROFILE.MENU.MY.BONUS.TEXT'].toString(),
            )
          }
        >
          <Box flexDirection="row">
            <Box fontSize="12px">{`${intl.messages['COMMON.PROFILE.MENU.MY.BONUS.VALUE.LABEL']} ${stammisGroup.pointValue}p`}</Box>
            <Box fontSize="12px">{`${intl.messages['COMMON.PROFILE.MENU.MY.BONUS.VOUCHERS.LABEL']} ${totalAphCheckAmount}:-`}</Box>
            {intl.messages['COMMON.PROFILE.MENU.MY.BONUS.TEXT'].toString()}
          </Box>
        </MenuItem>
      )}

      {isMember && <Divider />}
      {isMember && (
        <MenuItem
          onClick={() =>
            menuItemClick(
              '/klubb-hjartat/start/',
              intl.messages['COMMON.PROFILE.MENU.MY.PAGES.TEXT'].toString(),
            )
          }
        >
          {intl.messages['COMMON.PROFILE.MENU.MY.PAGES.TEXT'].toString()}
        </MenuItem>
      )}

      <MenuItem
        onClick={() =>
          menuItemClick(
            '/mina-recept/receptlistan/',
            intl.messages['COMMON.PROFILE.MENU.MY.RECIPES.TEXT'].toString(),
          )
        }
      >
        {intl.messages['COMMON.PROFILE.MENU.MY.RECIPES.TEXT'].toString()}
      </MenuItem>
      {isMember ? (
        <MenuItem
          onClick={() =>
            menuItemClick(
              '/klubb-hjartat/klubberbjudanden/',
              intl.messages['COMMON.PROFILE.MENU.CLUB.OFFERS.TEXT'].toString(),
            )
          }
        >
          {intl.messages['COMMON.PROFILE.MENU.CLUB.OFFERS.TEXT'].toString()}
        </MenuItem>
      ) : (
        <MenuItem
          onClick={() =>
            menuItemClick(
              `/klubb-hjartat/bli-medlem/`,
              intl.messages['COMMON.PROFILE.MENU.BECOME.MEMBER.TEXT'].toString(),
            )
          }
        >
          {intl.messages['COMMON.PROFILE.MENU.BECOME.MEMBER.TEXT'].toString()}
        </MenuItem>
      )}
      {isMember && (
        <MenuItem
          onClick={() =>
            menuItemClick(
              '/klubb-hjartat/orderhistorik/',
              intl.messages['COMMON.PROFILE.MENU.MY.ORDERS.TEXT'].toString(),
            )
          }
        >
          {intl.messages['COMMON.PROFILE.MENU.MY.ORDERS.TEXT'].toString()}
        </MenuItem>
      )}
      {isMember && (
        <MenuItem
          onClick={() =>
            menuItemClick(
              '/klubb-hjartat/mina-uppgifter/',
              intl.messages['COMMON.PROFILE.MENU.MY.INFO.TEXT'].toString(),
            )
          }
        >
          {intl.messages['COMMON.PROFILE.MENU.MY.INFO.TEXT'].toString()}
        </MenuItem>
      )}
      <Divider />
      <MenuItem onClick={onLogOut}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        {intl.messages['COMMON.PROFILE.MENU.LOGOUT.TEXT'].toString()}
      </MenuItem>
    </Menu>
  );
};
