import { BonusVouchersV4 } from '~/services/generated/CustomerClient';

export type BonusVouchersState = {
  data: BonusVouchersV4;
  loading: boolean;
  error: boolean;
  errorMessage: string | null;
  updated: number;
};

export const initialState: BonusVouchersState = {
  data: new BonusVouchersV4(),
  loading: false,
  error: false,
  errorMessage: null,
  updated: 0,
};
