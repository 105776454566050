import React from 'react';
import NextLink from 'next/link';
import { Box, Skeleton, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { notEmpty } from '@aph/utilities/not-empty';
import { appendLeadingSlash } from '@aph/utilities/slug';
import type { ICategoryReference } from '~/articles/generated/ArticlesClient';
import type { QueryResult } from './types';
import { Typography } from '@aph/ui/components/typography/typography';

type CategorySearchResultsProps = QueryResult<ICategoryReference[]>;
export const CategorySearchResults: React.FC<CategorySearchResultsProps> = ({
  isLoading,
  data,
}) => {
  let categories = null;

  if (isLoading) {
    categories = (
      <>
        <Skeleton variant="text" height={21} width={120} />
        <Skeleton variant="text" height={21} width={120} />
        <Skeleton variant="text" height={21} width={120} />
        <Skeleton variant="text" height={21} width={120} />
      </>
    );
  } else {
    categories = data
      ?.filter((category) => notEmpty(category.slug) && notEmpty(category.name))
      ?.map((category) => (
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        <NextLink href={appendLeadingSlash(category.slug!)} rel="nofollow" key={category.code}>
          <Box
            component="span"
            height="32px"
            borderRadius="100px"
            paddingX={1.5}
            paddingY={1}
            bgcolor={(theme) => theme.palette['color/background/visual']}
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              typography: (theme) => theme.typography.body1,
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '100%',
              color: (theme) => theme.palette['color/text/action-dark'],
            }}
          >
            {category.name}
          </Box>
        </NextLink>
      ));

    if ((data?.length ?? 0) < 1) {
      categories = (
        <Typography>
          <FormattedMessage id="COMMON.HEADER.SEARCH.NOHITS" />
        </Typography>
      );
    }
  }

  return (
    <Stack direction="row" gap={0.5} flexWrap="wrap">
      {categories}
    </Stack>
  );
};
