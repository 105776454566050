import React from 'react';
import { Box, Stack } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import type { IAphChecklistFields } from '~/contentful/types';
import { AphChecklistIcon } from './aph-checklist-icon.component';

type AphChecklistProps = {
  list: IAphChecklistFields;
};

export const AphChecklist = ({ list: { listItems, iconVariant } }: AphChecklistProps) => (
  <Stack component={List} gap={2} disablePadding my={2}>
    {listItems.map((item) => (
      <Box component={ListItem} key={item} disableGutters disablePadding m={0}>
        <Stack gap={1.5} direction="row">
          <AphChecklistIcon iconVariant={iconVariant} />
          <Typography component={ListItemText} m={0}>
            {item}
          </Typography>
        </Stack>
      </Box>
    ))}
  </Stack>
);
