import React from 'react';
import { Stack, Typography } from '@mui/material';
import { formatPrice } from '@aph/utilities/format-price/format-price';
import { ICAHeading } from '~/styles/fonts';
import type { TextSize } from '~/styles/text-size';
import theme from '~/styles/theme';

const priceSizeMapper: Partial<{ [textSize in TextSize]: string }> = {
  small: theme.spacing(2), // 16px
  medium: '26px',
  large: theme.spacing(4), // 32px
};

const labelSizeMapper: Partial<{ [textSize in TextSize]: string }> = {
  small: '14px',
  medium: '12px',
  large: '14px',
};

export const PriceFormatter: React.FC<{
  price: number;
  strikeThrough?: boolean;
  inline?: boolean;
  size?: TextSize;
  label?: React.ReactChild;
  color?: string;
  unit?: React.ReactChild;
}> = ({ price, strikeThrough, label, inline = false, color, unit, size = 'large' }) => (
  <Stack direction={inline ? 'row' : 'column'} gap={inline ? 0.5 : 0} alignItems="baseline">
    <Typography fontSize={labelSizeMapper[size]} color={theme.palette['color/text/subtle']}>
      {label}
    </Typography>
    <Typography
      data-testid="PRICE_FORMATTER_COMPONENT"
      component="span"
      fontSize={inline ? '14px' : priceSizeMapper[size]}
      fontFamily={inline ? 'inherit' : ICAHeading}
      fontWeight={inline ? 400 : 700}
      lineHeight={1}
      sx={{ textDecoration: strikeThrough ? 'line-through' : 'none' }}
      color={color}
    >
      {formatPrice(price)}
      {unit}
    </Typography>
  </Stack>
);
