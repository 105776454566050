/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Box, Drawer, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { parseEventTree, sendMainNavigationEvent } from '@aph/components/gtm/events/navigation-gtm';
import { useToggle } from '@aph/hooks/use-toggle';
import CloseIcon from '@aph/icons/close.svg';
import { ButtonCircle } from '@aph/ui/components/button-circle/button-circle';
import { Typography } from '@aph/ui/components/typography/typography';
import { IconButton } from '../icon-button/icon-button.component';
import type { FeatureToggleableLink } from '../link-list/link-list.component';
import { LinkList } from '../link-list/link-list.component';
import { PROMOTED_LINKS } from '../link-list/promoted-links';
import { ProductCategoryNavigation } from './product-category-navigation/product-category-navigation.component';
import { TOP_NAVIGATION_LINKS } from './top-navigation.component';

export const HamburgerMenu = () => {
  const [isMenuExpanded, toggle, setIsMenuExpanded] = useToggle();
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      setIsMenuExpanded(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, setIsMenuExpanded, toggle]);

  const handleLinkClick = (target: FeatureToggleableLink) =>
    sendMainNavigationEvent(parseEventTree(target.href));

  return (
    <>
      <ButtonCircle size="extraLarge" icon="Menu" onClick={toggle} variant="secondary" />
      <Drawer
        open={isMenuExpanded}
        anchor="left"
        PaperProps={{
          sx: (theme) => ({
            width: 284,
            borderTopRightRadius: theme.shape.borderRadius * 6,
            borderBottomRightRadius: theme.shape.borderRadius * 6,
          }),
        }}
        onClose={toggle}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          paddingY={3}
          paddingX={2}
          border={(theme) => `1px solid ${theme.palette['color/border/subtle']}`}
        >
          <Typography typography="headingExtraLarge" color="text-brand" asChild>
            <h1>
              <FormattedMessage id="COMMON.HEADER.MENU" />
            </h1>
          </Typography>
          <IconButton
            icon={CloseIcon}
            onClick={toggle}
            sx={(theme) => ({
              color: theme.palette['color/text/default'],
              '&:hover': { color: theme.palette['color/text/default'] },
            })}
          />
        </Stack>
        <Stack direction="column" gap={1}>
          <Box padding={1.25}>
            <LinkList
              links={TOP_NAVIGATION_LINKS}
              variant="secondary"
              onLinkClick={handleLinkClick}
              typography="bodyBig"
            />
          </Box>
          <Box padding={1.25}>
            <LinkList links={PROMOTED_LINKS} variant="primary" onLinkClick={handleLinkClick} />
          </Box>
          <Box padding={1.25}>
            <ProductCategoryNavigation />
          </Box>
        </Stack>
      </Drawer>
    </>
  );
};
