import { IconButton, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { type FooterSocialLogosLinkType, footerSocialLogosLinks } from '../links/links-data';

export const SocialIcons = () => {
  return (
    <Stack marginTop={{ xs: 2, sm: 0 }}>
      <Typography variant="subtitle1" color={(theme) => theme.palette['color/text/inverse']}>
        <FormattedMessage id="COMMON.FOOTER.SOCIAL.FOLLOW" />
      </Typography>
      <Stack direction="row">
        {footerSocialLogosLinks.map((link: FooterSocialLogosLinkType) => (
          <IconButton
            sx={{ padding: 0.5, ':first-of-type': { paddingLeft: 0 } }}
            key={link.href}
            color="primary"
            href={link.href}
            target="_blank"
          >
            {link.icon}
          </IconButton>
        ))}
      </Stack>
    </Stack>
  );
};
