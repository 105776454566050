// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getFeatureToggleName = (featureName: string | undefined, path = '') => {
  if (featureName) {
    return featureName;
  }
  if (path.startsWith('/nyheter')) {
    return 'aph_features_latestarticles';
  }
  if (path.startsWith('/tips-och-rad')) {
    return 'aph_features_tips_advice_page';
  }
  return '';
};

export { getFeatureToggleName };
