import { Box, Typography } from '@mui/material';
import type { FooterLinkType } from '../links/links-data';
import type { LinksProps } from './footer-links.component';
import { LinkList } from './link-list.component';

interface DesktopLinksProps extends LinksProps {
  footerLinkClickEvent: (args: { link: FooterLinkType }) => void;
}

export const DesktopLinks = ({ header, links, footerLinkClickEvent }: DesktopLinksProps) => {
  return (
    <>
      <Box paddingBottom={1}>
        <Typography
          paddingLeft={1}
          variant="bodyHeavy"
          color={(theme) => theme.palette['color/text/inverse']}
        >
          {header.title}
        </Typography>
      </Box>
      <LinkList links={links} onLinkClick={footerLinkClickEvent} />
    </>
  );
};
