import type { IncomingHttpHeaders } from 'http';
import pickBy from 'lodash/pickBy';
import type { ClientOptions as BaseClientOptions } from 'openapi-fetch';
import create from 'openapi-fetch';
import { Cookies } from 'react-cookie';
import { v4 as uuid } from 'uuid';
import { isServer } from './is-server';

type HeadersOptions = BaseClientOptions['headers'] | IncomingHttpHeaders;

interface BaseApiClientOptions {
  headers?: HeadersOptions;
}

export abstract class BaseApiClient<TPaths extends object> {
  protected client: ReturnType<typeof create<TPaths>>;

  public constructor(options?: BaseApiClientOptions) {
    let headers: BaseClientOptions['headers'] = {};
    if (isServer()) {
      if (!options?.headers) {
        throw new Error('Headers must be provided when running on the server');
      }
      headers = mapCookiesToHeaders(new Cookies(getCookiesFromHeaders(options.headers)));
    } else {
      headers = mapCookiesToHeaders(new Cookies());
    }

    this.client = create<TPaths>({
      baseUrl: getClientBaseUrl(),
      keepalive: true,
      credentials: 'include',
      redirect: 'follow',
      mode: 'cors', // this is the default but we want to be explicit
      headers,
    });
  }
}

function getCookiesFromHeaders(headers: BaseApiClientOptions['headers']) {
  let cookieHeaderAsString = '';

  if (headers instanceof Headers) {
    cookieHeaderAsString = headers.get('cookie') ?? '';
  } else if (Array.isArray(headers)) {
    const cookieHeader = headers.find((header) => header[0].toLowerCase() === 'cookie');
    cookieHeaderAsString = cookieHeader ? cookieHeader[1] : '';
  } else if (typeof headers === 'object') {
    const cookieHeader = headers.cookie;
    if (typeof cookieHeader === 'string') {
      cookieHeaderAsString = cookieHeader;
    }
  }

  return cookieHeaderAsString;
}

function mapCookiesToHeaders(cookies: Cookies) {
  const xAnonymousId = cookies.get<string>('aph_anonymous_id') ?? uuid();

  const eSalesHeaders = pickBy({
    'x-recommendations-session-id': cookies.get<string>('ApptusSessionId'),
    'x-recommendations-customer-id': cookies.get<string>('ApptusCustomerId'),
  });

  const headers = {
    'X-requested-with': 'fetch',
    'X-Aph-Api-Key': 'df6e4856-c145-43cf-8be4-2d0a1f0dbabb',
    'X-Anonymous-Id': xAnonymousId,
    'X-Correlation-Id': uuid(),
    ...eSalesHeaders,
  };

  return headers;
}

const isDevelopment = process.env.NODE_ENV === 'development';

function getClientBaseUrl() {
  // the purpose of this is to allow us to (in development) use different api/client urls on the server vs. the client
  // this, in tandem with url rewrites in nextjs config, allow us to avoid CORS issues from localhost
  // so for example, with rewrites, we can have /api/ ... be proxied to https://example.com/api (thus avoiding CORS)
  // but on the server you can't use relative urls so there we must use the full url, https://example.com/api (and server side CORS is not applicable)
  if (isDevelopment && isServer() && process.env.SERVER_BASE_URL) {
    return process.env.SERVER_BASE_URL;
  }

  return process.env.NEXT_PUBLIC_BASE_URL;
}
