import React from 'react';
import { Box, Stack } from '@mui/material';
import { useMatchRoute } from '@aph/hooks/use-match-route/use-match-route';
import { FeatureToggledNextLink } from '../../feature-toggled-next-link';
import type { FooterLinkType } from '../links/links-data';

export enum TextVariants {
  bodyv2 = 'bodyv2',
  body3 = 'body3',
}

type LinkListProps = {
  links: FooterLinkType[];
  onLinkClick?: (args: { link: FooterLinkType }) => void;
  gap?: number;
  variant?: TextVariants;
};

export const LinkList = ({
  links,
  variant = TextVariants.bodyv2,
  gap = 1,
  onLinkClick,
}: LinkListProps) => {
  const isActiveLink = useMatchRoute();

  return (
    <Stack
      gap={gap}
      direction="column"
      component="ul"
      sx={{
        marginBlockStart: 0,
        marginBlockEnd: 0,
        paddingInlineStart: 0,
        listStyleType: 'none',
      }}
    >
      {links.map((link) => (
        <Box component="li" key={link.href}>
          <FeatureToggledNextLink
            href={link.href}
            forceAnchorLink={link.isEpiLink}
            featureName={link.feature}
            className={isActiveLink(link.href, { exact: true }) ? 'active' : undefined}
            onClick={() => onLinkClick?.({ link })}
            underline="none"
            paddingY={0.5}
            paddingX={1}
            borderRadius={2}
            display="flex"
            color={(theme) => theme.palette['color/text/inverse']}
            paddingLeft={{ xs: 0, sm: 1 }}
            sx={{
              typography: (theme) =>
                variant === TextVariants.bodyv2 ? theme.typography.bodyv2 : theme.typography.body3,
              '&:hover': {
                background: (theme) => theme.palette['color/background/action'],
              },
            }}
          >
            {link.title}
          </FeatureToggledNextLink>
        </Box>
      ))}
    </Stack>
  );
};
