import { ContentfulImage } from '../../next-contentful-image/next-contentful-image';

type AssetImageProps = {
  src: string;
  description?: string;
  width?: number;
  height?: number;
};

export const AssetImage = ({ src, description, width, height }: AssetImageProps) => {
  return (
    <ContentfulImage
      src={src}
      alt={description ?? ''}
      width={width}
      height={height}
      sizes="100vw"
      style={{
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
      }}
    />
  );
};
