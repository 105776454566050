import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Badge, Dialog, Stack, SvgIcon } from '@mui/material';
import { useIntl } from 'react-intl';
import { GRID_MARGIN } from '@aph/components/layouts/page-layout-container';
import SearchIcon from '@aph/icons/magnifying-glass.svg';
import { ButtonCircle } from '@aph/ui/components/button-circle/button-circle';
import { Typography } from '@aph/ui/components/typography/typography';
import { useCartActions, useCartStore } from '~/model/cart/cart.store';
import { MiniCart } from '../mini-cart/mini-cart.container';
import { HamburgerMenu } from './hamburger-menu.component';
import { LogoLink } from './logo-link.component';
import { QuickSearch } from './quick-search/quick-search.component';

export const LowerNavigation = () => {
  const intl = useIntl();
  const { fetch: getCart, setIsMiniCartOpen } = useCartActions();

  useEffect(() => {
    getCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCartButtonClick = () => setIsMiniCartOpen(true);

  return (
    <>
      <Stack display={{ md: 'none' }} direction="row" paddingY={1.5} gap={1} alignItems="center">
        <HamburgerMenu />
        <QuickSearchTrigger
          placeholder={intl.formatMessage({ id: 'COMMON.HEADER.SEARCH.PLACEHOLDER.MOBILE' })}
          useFullScreenDialog
        />
        <CartButton onClick={handleCartButtonClick} />
      </Stack>
      <Stack
        display={{ xs: 'none', md: 'flex' }}
        direction="row"
        paddingY={1.5}
        gap={4}
        alignItems="center"
      >
        <LogoLink />
        <QuickSearchTrigger
          placeholder={intl.formatMessage({ id: 'COMMON.HEADER.SEARCH.PLACEHOLDER.DESKTOP' })}
        />
        <CartButton onClick={handleCartButtonClick} />
      </Stack>
      <MiniCart />
    </>
  );
};

const CartButton = ({ onClick }: { onClick?: () => void }) => {
  const { articleCount } = useCartStore();
  const intl = useIntl();

  return (
    <div className="relative flex">
      <ButtonCircle
        variant="secondary"
        size="extraLarge"
        icon="Cart"
        onClick={onClick}
        aria-label={intl.formatMessage({ id: 'CART.HEADING' })}
      />
      <Badge
        badgeContent={articleCount()}
        max={99}
        data-pw="mini-cart-badge"
        overlap="circular"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '&.MuiBadge-root': {
            position: 'initial',
          },
          '& .MuiBadge-badge': {
            color: (theme) => theme.palette['color/text/notice'],
            background: (theme) => theme.palette['color/background/notice'],
            fontWeight: 700,
            width: 24,
            height: 24,
            borderRadius: '100px',
          },
        }}
      />
    </div>
  );
};

type QuickSearchTriggerProps = {
  useFullScreenDialog?: boolean;
  placeholder: string;
};

const QuickSearchTrigger = ({ placeholder, useFullScreenDialog }: QuickSearchTriggerProps) => {
  const [isQuickSearchActive, setIsQuickSearchActive] = useState(false);
  const [bounds, setBounds] = useState<DOMRect>();
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      setIsQuickSearchActive(false);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  return (
    <>
      <Stack
        component="button"
        alignItems="center"
        direction="row"
        paddingY={1.5}
        borderRadius="100px"
        border="none"
        lineHeight={1}
        flex="1 1 auto"
        alignSelf="stretch"
        justifyContent="flex-start"
        gap={0.5}
        paddingX={{ xs: 2.5, md: 3 }}
        bgcolor={(theme) => theme.palette['color/background/default']}
        sx={{
          '&:hover': {
            cursor: 'text',
          },
          '&:focus-visible': {
            outline: (theme) => `1px solid ${theme.palette['color/border/action']}}`,
          },
        }}
        onClick={(event) => {
          setBounds(event.currentTarget.getBoundingClientRect());
          setIsQuickSearchActive(true);
        }}
      >
        <SvgIcon inheritViewBox sx={{ color: (theme) => theme.palette['color/icon/action'] }}>
          <SearchIcon />
        </SvgIcon>
        <Typography
          color="text-subtle"
          className="sx:max-w-[179px] md:max-w-auto whitespace-nowrap"
          asChild
        >
          <span>{placeholder}</span>
        </Typography>
      </Stack>
      <Dialog
        open={isQuickSearchActive}
        onClose={() => setIsQuickSearchActive(false)}
        maxWidth={false}
        sx={[
          {
            '& .MuiDialog-container': {
              position: 'relative',
            },
            '& .MuiDialog-paper': {
              top: `${bounds?.top}px`,
              left: useFullScreenDialog ? undefined : `${bounds?.left}px`,
              position: 'absolute',
              margin: 0,
              borderRadius: 6,
              maxHeight: '80vh',
              width: useFullScreenDialog ? `100%` : `${bounds?.width}px`,
              maxWidth: Object.entries(GRID_MARGIN).reduce(
                (acc, [breakpoint, margin]) => {
                  acc[breakpoint] = `calc(100% - (${margin} * 2))`;
                  return acc;
                },
                {} as Record<string, string>,
              ),
            },
          },
        ]}
      >
        <QuickSearch placeholder={placeholder} onClose={() => setIsQuickSearchActive(false)} />
      </Dialog>
    </>
  );
};
