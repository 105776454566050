import React from 'react';
import { theme } from '@aph/component-library';

export const CloseIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.41 12L20.71 4.71C20.8983 4.5217 21.0041 4.2663 21.0041 4C21.0041 3.7337 20.8983 3.47831 20.71 3.29C20.5217 3.1017 20.2663 2.99591 20 2.99591C19.7337 2.99591 19.4783 3.1017 19.29 3.29L12 10.59L4.71 3.29C4.61676 3.19676 4.50607 3.1228 4.38425 3.07234C4.26243 3.02188 4.13186 2.99591 4 2.99591C3.86814 2.99591 3.73758 3.02188 3.61575 3.07234C3.49393 3.1228 3.38324 3.19676 3.29 3.29C3.1017 3.47831 2.99591 3.7337 2.99591 4C2.99591 4.2663 3.1017 4.5217 3.29 4.71L19.29 20.71C19.383 20.8037 19.4936 20.8781 19.6154 20.9289C19.7373 20.9797 19.868 21.0058 20 21.0058C20.132 21.0058 20.2627 20.9797 20.3846 20.9289C20.5064 20.8781 20.617 20.8037 20.71 20.71C20.8037 20.617 20.8781 20.5064 20.9289 20.3846C20.9797 20.2627 21.0058 20.132 21.0058 20C21.0058 19.868 20.9797 19.7373 20.9289 19.6154C20.8781 19.4936 20.8037 19.383 20.71 19.29L13.41 12Z"
      fill={theme.palette['color/icon/default']}
    />
    <path
      d="M8.43 14.15L3.29 19.29C3.19627 19.383 3.12188 19.4936 3.07111 19.6154C3.02034 19.7373 2.9942 19.868 2.9942 20C2.9942 20.132 3.02034 20.2627 3.07111 20.3846C3.12188 20.5064 3.19627 20.617 3.29 20.71C3.38296 20.8037 3.49356 20.8781 3.61542 20.9289C3.73728 20.9797 3.86799 21.0058 4 21.0058C4.13201 21.0058 4.26272 20.9797 4.38458 20.9289C4.50644 20.8781 4.61704 20.8037 4.71 20.71L9.85 15.56C9.94258 15.4668 10.0159 15.3562 10.0657 15.2346C10.1156 15.1131 10.141 14.9829 10.1406 14.8515C10.1401 14.7201 10.1138 14.59 10.063 14.4688C10.0123 14.3476 9.93824 14.2376 9.845 14.145C9.75176 14.0524 9.6412 13.9791 9.51963 13.9292C9.39806 13.8794 9.26786 13.854 9.13646 13.8544C9.00507 13.8549 8.87505 13.8812 8.75384 13.932C8.63262 13.9827 8.52258 14.0568 8.43 14.15Z"
      fill={theme.palette['color/icon/default']}
    />
  </svg>
);
