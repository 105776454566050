import React, { useCallback, useEffect, useMemo } from 'react';
import { ClickAwayListener, Skeleton, Tooltip } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { getButtonSize } from '@aph/components/common/button/styles';
import type { CartEvent } from '@aph/components/gtm/events/cart-gtm';
import { sendAddToCartEvent, sendRemoveFromCartEvent } from '@aph/components/gtm/events/cart-gtm';
import type { IArticleReference } from '~/articles/generated/ArticlesClient';
import type { CartEventProductType } from '~/components/gtm/useGtm';
import { getCart, updateOrCreateLineItem } from '~/model/cart/cart.api';
import { useCartActions, useCartStore } from '~/model/cart/cart.store';
import type { ICart } from '~/services/generated/PurchaseClient';
import { QuantitySelector } from '../../common/quantity-selector/quantity-selector.component';
import {
  AddArticleToCartButton,
  type AddArticleToCartButtonProps,
} from './add-article-to-cart-button.component';

export type AddArticleToCartProps = CartEventProductType &
  Pick<AddArticleToCartButtonProps, 'size'> & {
    article?: IArticleReference;
  } & {
    listName: string | undefined;
  };

// this is just a wrapper around the api call to make it work with react-query that expects a promise to reject on error which the api doesn't do currently
async function updateOrCreateLineItemAsPromise(
  params: Parameters<typeof updateOrCreateLineItem>[0],
) {
  const result = await updateOrCreateLineItem(params);
  if (result.errorMessage) {
    throw new Error(result.errorMessage);
  }
  if (result.cart) {
    return result.cart;
  }

  throw new Error('Något gick fel, försök igen');
}

function useGetCart<TData = ICart>(select?: (data: ICart) => TData) {
  // we're using the store as the source of truth so we don't need to fetch the cart again here
  const { cart, updated } = useCartStore();
  const queryClient = useQueryClient();

  // sync cart from store to queryClient if it has newer data
  useEffect(() => {
    if ((queryClient.getQueryState(['getCart'])?.dataUpdatedAt ?? 0) < updated) {
      queryClient.setQueryData(['getCart'], cart);
    }
  }, [cart, queryClient, updated]);

  return useQuery({
    queryKey: ['getCart'],
    queryFn: () =>
      getCart().then((res) => {
        // the reason for this is the getCart return a promise that isn't correct in terms of typescript. It should be Promise<ICart>
        if (res.cart) {
          return res.cart;
        }
        throw new Error(res.errorMessage);
      }),
    gcTime: 0, // we're using the store as the source of truth so don't cache it
    staleTime: Infinity, // we're using the store as the source of truth so if we have data it's not stale
    enabled: Boolean(cart), // we're using the store as the source of truth so if we don't have a cart yet don't try to fetch it
    select,
    initialData: cart,
  });
}

const useGetLineItem = (articleCode: string | undefined) => {
  return useGetCart(
    (cart) => cart.lineItems?.find((i) => i.articleCode === articleCode) || undefined,
  );
};

export const AddArticleToCartComponent = ({ article, listName, size }: AddArticleToCartProps) => {
  const {
    articleCode,
    name,
    isSensitive,
    brandReferences,
    maxQuantityPerOrder,
    hasVariants,
    articleUrlSegment,
    activeSubstances,
    isAvailableForPurchase,
    requiresPrescriptionForPurchase,
    price,
  } = article || {};
  const { replaceCart } = useCartActions();
  const { data: cartId, isLoading: isLoadingCart } = useGetCart((cart) => cart.id);
  const { data: lineItem } = useGetLineItem(articleCode);

  const tracking = useMemo<Partial<CartEvent>>(
    () => ({
      id: articleCode,
      name,
      price: price?.webPrice,
      brand: brandReferences?.map((b) => b.name).join(', '),
      isSensitive,
      listName,
    }),
    [articleCode, brandReferences, isSensitive, name, price, listName],
  );

  const lineItemQuantity = lineItem?.quantity || 0;

  const queryClient = useQueryClient();

  const {
    mutateAsync: addOrUpdateCart,
    isError,
    isPending,
    error,
    reset,
  } = useMutation({
    mutationFn: updateOrCreateLineItemAsPromise,
    onSuccess: async (cart) => {
      // write the data back to the store to keep them in sync
      await replaceCart(cart);
    },
    // Always refetch after error
    onError: () => {
      return queryClient.invalidateQueries({ queryKey: ['getCart'] });
    },
  });

  const handleOnChangeQuantity = useCallback(
    async (oldQuantity: number, newQuantity: number) => {
      await addOrUpdateCart(
        {
          cartId: cartId ?? '',
          quantity: newQuantity,
          articleCode: articleCode || '',
          lineItemId: lineItem?.id ?? '',
          recommendationsInfo: article?.trackingId,
        },
        {
          onSuccess() {
            const eventData: CartEvent = {
              ...tracking,
              oldQuantity,
              newQuantity,
            };
            if (newQuantity > oldQuantity) {
              sendAddToCartEvent(eventData);
            } else {
              sendRemoveFromCartEvent(eventData);
            }
          },
        },
      );
    },
    [addOrUpdateCart, cartId, articleCode, lineItem?.id, article?.trackingId, tracking],
  );

  if (isLoadingCart) {
    return <Skeleton variant="rounded" height={getButtonSize(size)} />;
  }

  const addArticleToCartButtonProps: AddArticleToCartButtonProps = {
    loading: isPending,
    size,
    hasVariants,
    articleUrlSegment,
    activeSubstances,
    isAvailableForPurchase,
    requiresPrescriptionForPurchase,
    onClick: () => handleOnChangeQuantity(lineItemQuantity, lineItemQuantity + 1),
  };

  return lineItemQuantity > 0 ? (
    <QuantitySelector
      size={size}
      fullWidth
      data-testid="ADD_ARTICLE_TO_CART.BUYABLE_BUTTON.QUANTITY_SELECTOR"
      quantity={lineItemQuantity}
      error={error?.message}
      onDismissError={() => reset()}
      maxQuantity={maxQuantityPerOrder?.maxQuantity}
      maxQuantityReason={
        <FormattedMessage id={`MAXPURCHASE.REASON.${maxQuantityPerOrder?.reason}`} />
      }
      minQuantity={0}
      updateFunctionFromParent={handleOnChangeQuantity}
    />
  ) : (
    <ClickAwayListener onClickAway={() => reset()}>
      <Tooltip
        placement="top"
        arrow
        title={error?.message || false}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        open={isError}
      >
        <AddArticleToCartButton {...addArticleToCartButtonProps} />
      </Tooltip>
    </ClickAwayListener>
  );
};
