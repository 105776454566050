import React from 'react';
import NextLink from 'next/link';
import { Link, Skeleton, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import type { QueryResult } from './types';
import { Typography } from '@aph/ui/components/typography/typography';

type SuggestionsSearchResultsProps = QueryResult<string[]>;
export const SuggestionsSearchResults: React.FC<SuggestionsSearchResultsProps> = ({
  isLoading,
  data,
}) => {
  let suggestions = null;

  if (isLoading) {
    suggestions = (
      <>
        <Skeleton variant="text" height={21} width={120} />
        <Skeleton variant="text" height={21} width={120} />
        <Skeleton variant="text" height={21} width={120} />
        <Skeleton variant="text" height={21} width={120} />
      </>
    );
  } else {
    suggestions = data?.map((suggestion, index) => (
      <NextLink
        // eslint-disable-next-line react/no-array-index-key
        key={`${suggestion}-${index}`}
        passHref
        legacyBehavior
        prefetch={false}
        href={{
          pathname: '/soksida',
          query: { query: suggestion },
        }}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          rel="nofollow"
          underline="hover"
          color={(theme) => theme.palette['color/text/default']}
          variant="subheading"
        >
          {suggestion}
        </Link>
      </NextLink>
    ));

    if ((data?.length ?? 0) < 1) {
      suggestions = (
        <Typography>
          <FormattedMessage id="COMMON.HEADER.SEARCH.NOHITS" />
        </Typography>
      );
    }
  }

  return (
    <Stack direction="column" rowGap={1.25}>
      {suggestions}
    </Stack>
  );
};
