import toLower from 'lodash/toLower';

import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

export function useMatchRoute() {
  const router = useRouter();

  const currentRouteParts = useMemo(() => {
    const asPathWithoutQuery = router.asPath.split('?')[0];
    const asRouteParts = asPathWithoutQuery.split('/').filter((x) => x.length > 0);

    return asRouteParts.map(toLower);
  }, [router.asPath]);

  return useCallback(
    (path: string, options?: { exact?: boolean }) => {
      const asPathWithoutQuery = path.split('?')[0];
      const asRouteParts = asPathWithoutQuery
        .split('/')
        .filter((x) => x.length > 0)
        .map(toLower);

      if (options?.exact) {
        if (asRouteParts.length !== currentRouteParts.length) {
          return false;
        }
      }

      return asRouteParts.every((element, index) => element === currentRouteParts[index]);
    },
    [currentRouteParts],
  );
}
