import { Box, Stack, SvgIcon, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import KlarnaLogo from '@aph/images/klarna_logo.svg';
import SwishLogo from '@aph/images/swish_logo.svg';
import { FeatureToggledNextLink } from '../../feature-toggled-next-link';
import { footerLegalLinks, footerLegalLogosLinks } from '../links/links-data';
import { ImageLink } from './image-link';

export const PartnersAndLegal = () => {
  const getCurrentYear = () => new Date().getFullYear();

  const { apotekLegalLogo, vetPharmacyLogo, pharmacyLogo } = footerLegalLogosLinks;
  return (
    <>
      <Stack direction={{ xs: 'column', sm: 'row' }} marginY={1} justifyContent="space-between">
        <Box display="flex" flexDirection="row" alignSelf={{ xs: 'flex-start', sm: 'center' }}>
          <Stack width="100%" paddingRight={1} direction="row">
            <SvgIcon inheritViewBox sx={{ width: 80, height: 33 }}>
              <KlarnaLogo />
            </SvgIcon>
            <div className="bg-elevated flex h-[32px] w-[80px] items-center justify-center rounded-md">
              <SwishLogo />
            </div>
          </Stack>
        </Box>
        <Stack direction="row" spacing={{ xs: 2, sm: 12 }} paddingRight={4}>
          <Box marginRight={4}>
            <ImageLink
              imgSrc={apotekLegalLogo.imgSrc}
              imgAlt={apotekLegalLogo.imgAlt}
              height={apotekLegalLogo.imgHeight}
              width={apotekLegalLogo.imgWidth}
              href={apotekLegalLogo.href}
            />
          </Box>
          <Stack direction="row" spacing={2}>
            <ImageLink
              imgSrc={vetPharmacyLogo.imgSrc}
              imgAlt={vetPharmacyLogo.imgAlt}
              height={vetPharmacyLogo.imgHeight}
              width={vetPharmacyLogo.imgWidth}
              href={vetPharmacyLogo.href}
            />
            <ImageLink
              imgSrc={pharmacyLogo.imgSrc}
              imgAlt={pharmacyLogo.imgAlt}
              height={pharmacyLogo.imgHeight}
              width={pharmacyLogo.imgWidth}
              href={pharmacyLogo.href}
            />
          </Stack>
        </Stack>
      </Stack>
      <Box
        width="100%"
        height="1px"
        position="absolute"
        right={0}
        left={0}
        justifyContent="space-between"
        bgcolor={(theme) => theme.palette['color/border/default']}
      />
      <Box
        paddingY={2}
        height="fit-content"
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography
          order={{ xs: 2, sm: 1 }}
          color={(theme) => theme.palette['color/text/inverse']}
          variant="bodyv2"
          width={{ xs: '100%', sm: 'fit-content' }}
          marginTop={{ xs: 3, sm: 0 }}
        >
          <FormattedMessage
            id="COMMON.FOOTER.LEGAL.COPYRIGHT_TEXT"
            values={{ currentYear: getCurrentYear() }}
          />
        </Typography>
        <Stack
          width={{ xs: '100%', sm: 'fit-content' }}
          order={{ xs: 1, sm: 2 }}
          direction={{ xs: 'column', sm: 'row' }}
          spacing={3}
        >
          {footerLegalLinks.map((link) => (
            <FeatureToggledNextLink
              key={link.href}
              href={link.href}
              style={{ textDecoration: 'none' }}
              forceAnchorLink={link.isEpiLink}
            >
              <Typography variant="bodyv2" color={(theme) => theme.palette['color/text/inverse']}>
                {link.title}
              </Typography>
            </FeatureToggledNextLink>
          ))}
        </Stack>
      </Box>
    </>
  );
};
