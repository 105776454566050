import React from 'react';
import { type RenderNode } from '@contentful/rich-text-react-renderer';
import type { Document } from '@contentful/rich-text-types';
import { isRichText, renderRichText } from './render';

// https://github.com/contentful/rich-text/tree/master/packages/rich-text-react-renderer

type RichTextComponentProps = {
  field: Document | undefined;
  overrideNodes?: RenderNode;
};

export const RichTextComponent = ({ field, overrideNodes }: RichTextComponentProps) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>{isRichText(field) ? renderRichText(field, overrideNodes) : field}</>
);
